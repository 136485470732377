import gql from 'graphql-tag';

export default gql`
  fragment Staff on staff_staff_Entry {
    email
    id
    phoneNumber
    position
    previousStaffMember
    preventProductDisplay
    slug
    title
    twitterHandle
    uri
    description
    photo {
      filename
      id
      url
      title
    }
    staffTeamAssignment {
      slug
    }
  }
`;
