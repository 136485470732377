import gql from 'graphql-tag';

export default gql`
  query JobsQuery {
    jobTabs: categories(groupId: 6) {
      title
      ... on positionType_Category {
        body
      }
    }
    positions: entries(public: true, section: "positions") {
      ... on positions_positions_Entry {
        id
        title
        slug
        positionType {
          ... on positionType_Category {
            title
          }
        }
      }
    }
    fellows: entry(section: "fellows") {
      id
      title
      uri

      ... on fellows_fellows_Entry {
        fellowYear
        fellowsFormer {
          ...formerFellow
        }
        fellowsCurrent {
          ...fellow
        }
      }
    }
    cultureTabs: entries(section: "culture") {
      ... on culture_culture_Entry {
        id
        slug
        title
        cultureBody
      }
    }
  }

  fragment fellow on staff_staff_Entry {
    id
    title
    position
    url
    slug
    photo {
      id
      title
      url
    }
  }

  fragment formerFellow on staff_staff_Entry {
    id
    title
    position
    url
    slug
  }
`;
