import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query EventPageQuery($slug: [String]) {
    event: entry(
      orderBy: "eventStartDate desc",
      slug: $slug,
      section: "events",
    ) {
      id
      slug
      title
      postDate
      ... on events_events_Entry {
        eventDescription
        eventBriteId
        eventEndDate
        eventStartDate
        eventHidePressContact
        eventLocationAddress
        eventLocationCity
        eventLocationState
        eventLocationName
        eventLocationZip

        eventContact {
          __typename
          id
          title

          ... on staff_staff_Entry {
            ...Staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            email
            position
          }
        }

        eventPhotos {
          url
          id
        }

        eventIssue {
        ... on issues_Category {
            id
            issueIcon
            slug
            title
          }
        }

        featuredVideo {
          ... on featuredVideo_youtubeVideo_BlockType {
            youtubeId
          }
          ... on featuredVideo_facebookVideo_BlockType {
            facebookVideoUrl
          }
        }
      }
    }

    globals: globalSet(handle: "companyInfo") {
      ... on companyInfo_GlobalSet {
        pressContact {
          id
          title
          url
          ... on staff_staff_Entry {
            ...Staff
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
