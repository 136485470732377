import gql from 'graphql-tag';

export default gql`
  query LegalPagePrivacyPolicyQuery {
    legal: globalSets(handle: "legal") {
      ... on legal_GlobalSet {
        privacyPolicy
      }
    }
  }
`;
