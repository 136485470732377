import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Imgix from 'react-imgix';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';

import Content from '../../Content';
import './Board.css';

function Board({ board }) {
  if (board && !board.length) return null;

  return (
    <div className="ProductPageBoard">
      <h2 className="mb-4 ProductPageBoard__title">Board Members</h2>
      {board.map((member) => (
        <Row className="pb-5">
          <Col xs={12} md={9}>
            {member.memberName && <h2 className="mb-2">{member.memberName}</h2>}
            <Content
              content={member.blurb}
              className="ProductPageBoard__content"
            />
            <ul className="ProductPageBoard__links">
              {member.bio && (
                <li>
                  <a href={member.bio} className="text-link" target="_blank">
                    <FontAwesomeIcon
                      icon={['fas', 'user']}
                      className="mr-1"
                      size="lg"
                    />
                    Bio
                  </a>
                </li>
              )}
              {member.twitter && (
                <li>
                  <a
                    href={`https://www.twitter.com/${member.twitter}`}
                    className="text-twitter-blue"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={['fab', 'twitter']}
                      className="mr-1"
                      size="lg"
                    />
                    @{member.twitter}
                  </a>
                </li>
              )}
              {member.website && (
                <li>
                  <a
                    href={member.website}
                    className="text-brand-primary"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'home']}
                      className="mr-1"
                      size="lg"
                    />
                    Website
                  </a>
                </li>
              )}
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <div className="w-full">
              <Imgix
                src={member.photo[0].url}
                width={member.photo[0].width}
                height={member.photo[0].height}
                className="ProductPageBoard__image"
              />
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default Board;
