import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

/**
 * Embedded video content.
 */
const VideoEmbed = (props) => (
  <div
    className={classNames(
      'VideoEmbed',
      'embed-responsive',
      `embed-responsive-${props.aspectRatio}`,
      props.className,
    )}
  >
    <ReactPlayer
      className="embed-responsive-item"
      height={null}
      url={props.url}
      width={null}
      youtubeConfig={{ playerVars: { controls: 1 } }}
    />
  </div>
);

VideoEmbed.defaultProps = {
  aspectRatio: '16by9',
};

VideoEmbed.propTypes = {
  /**
   * The video aspect ratio. One of: 21by9, 16by9, 4by3, 1by1.
   */
  aspectRatio: PropTypes.string.isRequired,
  /**
   * A title for the video.
   */
  title: PropTypes.string,
  /**
   * The video url
   */
  url: PropTypes.string.isRequired,
};

export default VideoEmbed;
