import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash-es/get';

import Content from '../Content';

const Culture = ({ culture }) => (
  <div className="Culture">
    <div>
      <Row>
        <Col xs={12}>
          <h1 className="mb-4">{culture.title}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Content
            content={get(culture, 'cultureBody', 'No content.')}
          />
        </Col>
      </Row>
    </div>
  </div>
);

export default Culture;
