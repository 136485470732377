import React from 'react';
import classNames from 'classnames';
import get from 'lodash-es/get';
import { NavLink, Route } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import AppHelmet from '../../AppHelmet';
import Content from '../../Content';
import Divider from '../../Divider';
import Internship from '../../../containers/Internship';
import Notice from '../Notice';
import SideNavList from '../../SideNavList';
import TopicLabel from '../../TopicLabel';

const InternshipsContent = ({ data, match, positions }) => {
  const internships = positions.filter(position => position.positionType[0].title === 'Internships');
  const content = get(data, 'body', 'No content.');

  return (
    <div className="InternshipsContent">
      <AppHelmet title="Internships" />

      <Row className="mb-4">
        <Col xs={12} lg={8} className="mb-3 mb-lg-0">
          <Content content={content} />
        </Col>
        <Col xs={12} lg={4}>
          <Notice />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Divider className="mb-4 mb-lg-5" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4} className="pr-lg-5">
          <TopicLabel name="Available Internships" className="mb-2" />
          <SideNavList className="mb-4">
            <ul className="list-unstyled">
              {internships.map((internship, index) => {
                const active =
                  RegExp('^/internships/?$').test(match.url) && index === 0;
                return (
                  <li key={internship.id}>
                    <NavLink
                      to={{
                        pathname: `/internships/${internship.slug}`,
                        state: { noScroll: true },
                      }}
                      className={classNames({ active })}
                    >
                      {internship.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </SideNavList>
        </Col>
        <Col xs={12} lg={8}>
          <Route
            exact
            path="/internships"
            component={() => {
              if (internships.length > 0) {
                return <Internship slug={internships[0].slug} />;
              }
              return <h1>No Internships were found.</h1>;
            }}
          />
          <Route
            exact
            path="/internships/:slug"
            component={() => <Internship slug={match.params.slug} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InternshipsContent;
