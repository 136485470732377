import gql from 'graphql-tag';

export default gql`
  query CategoriesQuery {
    globalSets(handle: "subscriptions") {
      ... on subscriptions_GlobalSet {
        globalSubscribe
      }
    }    
  }
`;
