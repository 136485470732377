import gql from 'graphql-tag';

export default gql`
  query InternshipQuery($slug: [String]) {
    internship: entry(slug: $slug) {
      id
      slug
      title
      ... on positions_positions_Entry {
        body
        positionUrl
      }
    }
  }
`;
