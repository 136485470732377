import moment from 'moment-timezone';
import formatUnix from './formatUnix';

// dateTime should be a unix number
const formatDate = (dateTime = undefined, format = 'LL') => {
  if (!dateTime) { return null; }

  return moment.unix(formatUnix(dateTime),).tz('America/New_York').format(format)
};

export default formatDate;
