import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

import SocialLink from '../SocialLink';

import './SocialSharing.css';
import formatDuration from '../../utils/formatDuration';
import formatDate from '../../utils/formatDate';

class SocialSharing extends Component {
  static propTypes = {
    body: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    productType: PropTypes.string,
    engagement: PropTypes.string,
    postDate: PropTypes.string,
    trackClicks: PropTypes.bool,
  };

  static defaultProps = {
    body: null,
    onClick: () => {},
    title: null,
    productType: null,
    engagement: null,
    postDate: null,
    trackClicks: false,
  };

  constructor() {
    super();

    this.state = {
      print: null,
      url: null,
    };
  }

  componentDidMount() {
    // This may seem weird, but this allows us to use the window functions while
    // not breaking our SSR app.
    if (typeof window !== 'undefined') {
      this.setState({
        print: window.print,
        url: window.location.href,
        clickEvent: window.clickEvent,
      });
    }
  }

  print() {
    if (this.state.print) {
      this.state.print();
    }
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  fireDataLayerEvent(platform) {
    if (this.props.trackClicks) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'content_share',
        platform_name: platform, // e.g. "Twitter"
        content_title: this.props.title, // e.g. "Luxury or Necessity? Digging into Clean Energy and Climate Messaging in the 2024 Election"
        content_type: this.props.productType, // e.g. "Memo"
        content_published_date: formatDate(this.props.postDate), // e.g. June 17, 2024
        content_length: this.props.engagement ? `${formatDuration(this.props.engagement)} read` : null, // e.g. 6 minute read
      });
    }
  }

  render() {
    const { print, url, } = this.state;
    const { body, className, onClick, title } = this.props;

    if (!url) {
      return null;
    }

    return (
      <div className={classNames('SocialSharing', className)}>
        <ul>
          <li className="twitter-button">
            <TwitterShareButton
              beforeOnClick={() => {
                onClick();
                this.fireDataLayerEvent('twitter');
              }}
              title={title}
              url={url}
            >
              <SocialLink className="twitter" icon="twitter" />
            </TwitterShareButton>
          </li>

          <li className="facebook-button">
            <FacebookShareButton
              beforeOnClick={() => {
                onClick();
                this.fireDataLayerEvent('facebook');
              }}
              quote={body}
              url={url}
            >
              <SocialLink className="facebook" icon="facebook-f" />
            </FacebookShareButton>
          </li>

          <li className="linked-in-button">
            <LinkedinShareButton
              description={body}
              beforeOnClick={() => {
                onClick();
                this.fireDataLayerEvent('linkedin');
              }}
              title={title}
              url={url}
            >
              <SocialLink className="linkedin" icon="linkedin-in" />
            </LinkedinShareButton>
          </li>

          <li className="email-button">
            <a
              href={`mailto:?subject=${title}&body=${url}`}
              onClick={() => {this.fireDataLayerEvent('email')}}
            >
              <SocialLink className="email" icon="envelope" faLibrary="fas" />
            </a>
          </li>

          {print && (
            <li
              className="print-button"
              onClick={() => {
                this.fireDataLayerEvent('print');
                print();
              }}
            >
              <SocialLink className="print" icon="print" faLibrary="fas" />
            </li>
          )}
        </ul>
      </div>
    );
  }
}

export default SocialSharing;
