import gql from 'graphql-tag';

export default gql`
  query PressPageResults(
    $limit: Int,
    $offset: Int,
    $relatedElement: [QueryArgument],
    $section: [String],
  ) {
    totalCount: entryCount(
      public: true,
      section: $section,
      relatedTo: $relatedElement,
    )

    entries(
      limit: $limit,
      offset: $offset,
      orderBy: "postDate desc"
      postDate: "> 0",
      public: true,
      relatedTo: $relatedElement,
      section: $section,
    ) {
      id
      postDate
      title
      slug

      ... on pressReleases_pressReleases_Entry {
        postDate
        issues {
          title
          id
        }
      }

      ... on inTheNews_inTheNews_Entry {
        publicationName
        newsItemUrl

        newsItemFeaturedImage {
          id
          url
          title
        }

        publicationLogo {
          id
          url
          title
        }

        issues {
          title
          id
        }
      }
    }
  }
`;
