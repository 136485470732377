import React from 'react';
import classNames from 'classnames';
import get from 'lodash-es/get';
import { NavLink, Route } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import AppHelmet from '../../AppHelmet';
import AuthorList from '../../AuthorList';
import Content from '../../Content';
import Divider from '../../Divider';
import Fellowship from '../../../containers/Fellowship';
import Notice from '../Notice';
import SideNavList from '../../SideNavList';
import TopicLabel from '../../TopicLabel';

const FellowshipsContent = ({ data, fellows, positions, match }) => {
  const fellowships = positions.filter(position => position.positionType[0].title === 'Fellows Program');
  const content = get(data, 'body', 'No content.');
  const year = fellows.fellowYear;
  const currentFellows = get(fellows, 'fellowsCurrent', []);
  const formerFellows = get(fellows, 'fellowsFormer', []);

  return(
    <div className="FellowshipsContent">
      <AppHelmet title="Fellows Program" />

      <Row className="mb-4">
        <Col xs={12} lg={8} className="mb-3 mb-lg-0">
          <Content content={content} />
        </Col>
        <Col xs={12} lg={4}>
          <Notice />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Divider className="mb-4 mb-lg-5" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4} className="pr-lg-5">
          <TopicLabel name="Issue Fellowships" className="mb-2" />
          <SideNavList className="mb-4">
            <ul className="list-unstyled">
              {fellowships.map((fellowship, index) => {
                const active = RegExp('^/about/fellows-program/?$').test(match.url) && index === 0;
                return(
                  <li key={fellowship.id}>
                    <NavLink to={{pathname: `/about/fellows-program/${fellowship.slug}`, state: { noScroll: true }}} className={classNames({ active })}>
                      {fellowship.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </SideNavList>
          <TopicLabel name={`${year} Fellows`} className="mb-4" />
          <AuthorList small authors={currentFellows} className="mb-4" />
          <TopicLabel name="Former Fellows" className="mb-4" />
          <AuthorList small authors={formerFellows} className="mb-4" showImage={false} />
        </Col>
        <Col xs={12} lg={8}>
          <Route exact path="/about/fellows-program" component={() => {
            if (fellowships.length > 0){
              return(
                <Fellowship slug={fellowships[0].slug} />
              );
            }
            return(
              <h1>No Fellow Programs were found.</h1>
            );
          }} />
          <Route exact path="/about/fellows-program/:slug" component={() => (
            <Fellowship slug={match.params.slug} />
          )} />
        </Col>
      </Row>
    </div>
  )
};

export default FellowshipsContent;
