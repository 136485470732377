import gql from 'graphql-tag';

export default gql`
  query ImpactPageQuery {
    entries(section: "impact") {
      id
      slug
      ... on impact_impact_Entry {
        impactShortTitle
      }
    }
  }
`;
