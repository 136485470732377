import React, { Component } from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

const withIssueFilter = (WrappedComponent) => {
  class IssueFilterWrapper extends Component {
    constructor() {
      super();

      this.onIssueIDChange = this.onIssueIDChange.bind(this);
      this.setIssueID = this.setIssueID.bind(this);
      this.setIssueIDFromQueryString = this.setIssueIDFromQueryString.bind(this);
      this.toggleIssueID = this.toggleIssueID.bind(this);

      this.state = {
        currentIssueID: null,
      };
    }

    componentWillMount() {
      this.setIssueIDFromQueryString();
    }

    componentWillReceiveProps(nextProps) {
      const currentIssueID = this.issueIDFromQueryString(this.props.location.search);
      const nextIssue = this.issueIDFromQueryString(nextProps.location.search);

      if (currentIssueID !== nextIssue) {
        this.setIssueID(nextIssue);
      }
    }

    currentIssueID() {
      return this.issueIDFromQueryString(this.props.location.search) || null;
    }

    onIssueIDChange(issueID = null) {
      const { contentType, author, q = '' } = queryString.parse(this.props.location.search);

      this.props.history.push({
        search: `?${queryString.stringify({ contentType, author, issueID, q })}`
      });
    }

    issueIDFromQueryString(search) {
      const { issueID = null } = queryString.parse(search);
      return issueID;
    }

    setIssueID(issueID = null) {
      return this.setState({ currentIssueID: Number(issueID) });
    }

    toggleIssueID(tagObject = null) {
      const issueID = (tagObject) ? tagObject.id : tagObject;
      const { contentType, q = null } = queryString.parse(this.props.location.search);

      let parsed = q ? { contentType, q } : { contentType };

      // Issue filter should always be chained first, so we wipe out the pagination params if it changes
      parsed = Number(this.state.currentIssueID) === Number(issueID) ? parsed : Object.assign(parsed, { issueID });

      this.props.history.push({
        pathname: this.props.match.url,
        search: `?${queryString.stringify(parsed)}`
      });
    }

    setIssueIDFromQueryString() {
      const issueID = this.currentIssueID();
      return this.setIssueID(issueID);
    }

    render() {
      const { currentIssueID } = this.state;

      return (
        <WrappedComponent
          issueFilter={{
            currentIssueID,
            onIssueIDChange: this.onIssueIDChange,
            toggleIssueID: this.toggleIssueID,
          }}
          {...this.props}
        />
      );
    }
  }

  return withRouter(IssueFilterWrapper);
}

export default withIssueFilter;
