import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import issueFilterShape from '../../shapes/issueFilterShape';
import LoadingSpinner from '../../components/LoadingSpinner';
import PaginatedEntries from '../../components/PaginatedEntries';
import paginationShape from '../../shapes/paginationShape';
import withIssueFilter from '../../wrappers/withIssueFilter';
import withPagination from '../../wrappers/withPagination';
import { itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

class AboutPageBioEntries extends Component {
  static propTypes = {
    data: dataShape.isRequired,
    issueFilter: issueFilterShape.isRequired,
    navLinks: PropTypes.array.isRequired,
    pagination: paginationShape.isRequired,
    staffID: PropTypes.number.isRequired,
  }

  constructor() {
    super();

    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps)  {
    // If the issue filter has changed and we are refetching based on variable changes (i.e: not from the cache)
    // show the loading spinner so the pagination isn't displaying when we don't have up to date pagination info
    const previousIssue = get(this.props, 'issueFilter.currentIssueID');
    const nextIssue = get(nextProps, 'issueFilter.currentIssueID');
    const networkStatus = get(nextProps, 'data.networkStatus');
    const currentSearchQuery = get(this.props, 'search.currentSearchQuery');
    const nextSearchQuery = get(nextProps, 'search.currentSearchQuery');

    if ((previousIssue !== nextIssue || currentSearchQuery !== nextSearchQuery) && networkStatus === 2) {
      return this.setState({ loading: true });
    }

    return this.setState({ loading: false });
  }

  render() {
    const { data, pagination } = this.props;
    const { error, loading, relatedEntries, totalCount } = data;

    if ((loading && !relatedEntries) || this.state.loading) { return (<LoadingSpinner fullPage={false} />); }
    if (error) { return (<Error error={error} />); }

    if (!relatedEntries) { return null; }

    const items = itemsFromEntries(relatedEntries);

    return (
      <PaginatedEntries
        items={items}
        loading={loading}
        noItemsMessage="No results found"
        pagination={pagination}
        totalItems={totalCount}
      />
    );
  }
}

const withGraphql = graphql(query, {
  options: ({ issueFilter: { currentIssueID }, match: { params, path }, navLinks, pagination, staffID }) => {
    const variables = {
      limit: pagination.limit || 5,
      offset: pagination.offset || 0,
      relatedElement: currentIssueID ? ['and', staffID, currentIssueID ] : [staffID],
      slug: params.slug,
      section: (navLinks.length === 1 && navLinks[0].title === 'Products') || path !== '/about/:category/:slug/media'
        ? ['products', 'series']
        : 'inTheNews',
    }

    return ({
      variables,
    });
  },
})(AboutPageBioEntries);

export default withIssueFilter(withPagination(withGraphql, { limit: 5 }));
