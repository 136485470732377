import gql from 'graphql-tag';

export default gql`
  query {
    globalSets(handle: "companyInfo") {
      ... on companyInfo_GlobalSet {
        globalAddressLine1
        globalAddressLine2
        globalAddressLine3
        globalEmail
        globalPhone
        globalFax
        globalTumblr
        globalTwitter
        globalFacebook
        globalLinkedin
      }
    }
    donateContent: entry(section: "donate") {
      ... on donate_donate_Entry {
        id
        slug
        title
        body
      }
    }
  }
`;
