import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';
import { RelatedProductsProducts } from '../../fragments/RelatedProducts';

const ProductPageQuery = gql`
  query ProductPageQuery($slug: [String]) {
    product: entry(slug: $slug, type: "products") {
      dateCreated
      id
      postDate
      slug
      title
      uri

      ... on products_products_Entry {
        productBlurb
        productBody
        productEndnotes
        productPublicationSlug
        productPublicationUpdateDate
        productReadingTime
        productTakeaways
        slug

        featuredVideo {
          ... on featuredVideo_youtubeVideo_BlockType {
            youtubeId
          }
          ... on featuredVideo_facebookVideo_BlockType {
            facebookVideoUrl
          }
        }

        productIssue {
          id
          slug
          title
          uri
          level

          ... on issues_Category {
            issueIcon
          }
        }

        productAuthors {
          __typename
          id
          slug
          title

          ... on staff_staff_Entry {
            ...Staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }

        autogeneratedPdfUrl

        productPdfOverrideFile {
          id
          mimeType
          title
          url
        }

        productDownloads {
          id
          mimeType
          title
          url
        }

        ...RelatedProductsProducts

        productFeaturedImage {
          url
          id
          title
        }

        productFeaturedImageUrl
        productSponsor

        productType {
          id
          slug
          title
        }

        board {
          ... on board_boardMember_BlockType {
            id
            bio
            blurb
            memberName
            twitter
            website
            photo {
              id
              height
              url
              width
            }
          }
        }
      }
    }

    subscriptionForms: entries(section: "subscriptionForms") {
      ... on subscriptionForms_subscriptionForms_Entry {
        subscriptionFormUrl
        subscriptionFormIssues {
          id
          slug
        }
      }
    }
  }

  ${RelatedProductsProducts}
  ${StaffFragment}
`;

export default ProductPageQuery;
