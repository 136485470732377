import PropTypes from 'prop-types';

import imageShape from './imageShape';

const authorShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  issues: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  photo: PropTypes.arrayOf(imageShape),
  position: PropTypes.string,
  slug: PropTypes.string,
  showImage: PropTypes.bool,
  title: PropTypes.string,
  twitterHandle: PropTypes.string,
});

export default authorShape;
