import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import SocialLinksComponent from '../../components/SocialLinks';

import query from './query';

const SocialLinks = ({ className, data, large, showTitles = false }) => {
  const { error, loading } = data;

  // The small version is used in the footer and doesn't need a spinner
  if (loading && !large) { return null; }
  if (loading && large) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }

  return (
    <SocialLinksComponent
      className={className}
      facebook={get(data, 'globalSets[0].globalFacebook')}
      instagram={get(data, 'globalSets[0].globalInstagram')}
      large={large}
      linkedin={get(data, 'globalSets[0].globalLinkedin')}
      showTitles={showTitles}
      twitter={get(data, 'globalSets[0].globalTwitter')}
      youtube={get(data, 'globalSets[0].globalYoutube')}
    />
  );
};

SocialLinks.propTypes = {
  data: dataShape,
  large: PropTypes.bool,
};

export default graphql(query)(SocialLinks);
