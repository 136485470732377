import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import Imgix from 'react-imgix';

import AppFixedHeader from '../../containers/AppFixedHeader';
import Divider from '../Divider';
import EntryCard from '../EntryCard';
import FeaturedHomeEntryItem from './FeaturedHomeEntryItem';
import Highlight from '../Highlight';
import SubscribeBox from '../../containers/SubscribeBox';
import TwitterFeed from '../../containers/TwitterFeed';

import TopicCarousel from './TopicCarousel';
import WhoWeAre from '../WhoWeAre';

const HomePage = ({
  featuredEntryItem,
  upperContent = [],
  lowerContent = [],
}) => {
  return (
  <div className="HomePage pt-lg-5">
    <AppFixedHeader />

    <MediaQuery maxWidth={991}>
      <FeaturedHomeEntryItem item={featuredEntryItem} />

      <TopicCarousel items={upperContent} />
    </MediaQuery>

    <MediaQuery minWidth={992}>
      <Grid fluid>
        <Row>
          <Col xs={12} lg={9} className="pr-lg-4">
            <FeaturedHomeEntryItem item={featuredEntryItem} />
          </Col>
          <Col xs={12} lg={3} className="feature-carousel">
            <TopicCarousel items={upperContent} />
          </Col>
        </Row>
      </Grid>
    </MediaQuery>

    <WhoWeAre />

    <Grid fluid className="mb-5">
      <Row>
        <Col xs={12} md={6} lg={4} className="pb-4">
          <Highlight>
            <Imgix
              aggressiveLoad
              src="https://thirdway.imgix.net/assets/about-us-homepage.jpg"
              type="picture"
            >
              <Imgix
                aggressiveLoad
                imgProps={{
                  media: '(min-width: 768px)',
                  alt: 'What is our Vision?',
                }}
                src="https://thirdway.imgix.net/assets/about-us-homepage.jpg"
                type="source"
                width={768}
                height={1000}
              />
              <Imgix
                aggressiveLoad
                crop="top"
                imgProps={{
                  media: '(min-width: 500px)',
                  alt: 'What is our Vision?',
                }}
                src="https://thirdway.imgix.net/assets/about-us-homepage.jpg"
                type="source"
                width={768}
                height={400}
              />
              <Imgix
                aggressiveLoad
                crop="top"
                imgProps={{ alt: 'What is our Vision?' }}
                src="https://thirdway.imgix.net/assets/about-us-homepage.jpg"
                type="img"
                width={768}
                height={768}
              />
            </Imgix>
            <div className="content">
              <h2>About Third Way</h2>
              <p className="mb-3">
                We are fighting for <strong>opportunity</strong>, so everyone
                has the chance to earn a good life; progress on social issues,
                so all have the <strong>freedom</strong> to live the lives they
                choose; and <strong>security</strong>, so we are protected from
                21st century global threats.
              </p>
              <Link to="/about" className="Button primary small text-uppercase">
                Learn More
              </Link>
            </div>
          </Highlight>
        </Col>

        <Col xs={12} md={6} lg={8}>
          {(lowerContent[0] || lowerContent[1]) && (
            <Row>
              {lowerContent[0] && (
                <Col xs={12} lg={6} className="pb-4">
                  <EntryCard item={lowerContent[0]} large />
                </Col>
              )}

              {lowerContent[1] && (
                <Col xs={12} lg={6} className="pb-4">
                  <EntryCard item={lowerContent[1]} large />
                </Col>
              )}
            </Row>
          )}

          {(lowerContent[2] || lowerContent[3]) && (
            <Row>
              {lowerContent[2] && (
                <Col xs={12} lg={6} className="pb-4">
                  <EntryCard item={lowerContent[2]} large />
                </Col>
              )}

              {lowerContent[3] && (
                <Col xs={12} lg={6} className="pb-4">
                  <EntryCard item={lowerContent[3]} large />
                </Col>
              )}
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="my-5">
            <SubscribeBox />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Divider className="mb-5 mt-2" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={7}>
          <h2>On Twitter</h2>
        </Col>
      </Row>

      <Row>
        <Col xs={12} lg={7}>
          <ul className="list-inline mb-3">
            <li className="list-inline-item">
              <a
                href="https://twitter.com/ThirdwayTweet"
                className="twitter-handle"
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} className="mr-1" />
                Follow @ThirdwayTweet
              </a>
            </li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <TwitterFeed handles={['ThirdwayTweet']} />
        </Col>
      </Row>
    </Grid>
  </div>
)};

export default HomePage;
