import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query {
    companyInfo: globalSets(handle: "companyInfo") {
      ... on companyInfo_GlobalSet {
        globalAddressLine1
        globalAddressLine2
        globalAddressLine3
        globalEmail
        globalPhone
        globalFax
        globalTumblr
        globalTwitter
        globalFacebook
        globalLinkedin
        pressContact {
          id
          ... on staff_staff_Entry {
            ...Staff
          }
        }
        eventContact {
          id
          ... on staff_staff_Entry {
            ...Staff
          }
        }
      }
    }
    categories(groupId: 3, level: 1) {
      id
      slug
      title
      ... on issues_Category {
        issueHidden
        issueIcon
        issueSocialMediaAccount {
          ... on issueSocialMediaAccount_twitterAccount_BlockType {
            twitterHandle
          }
          ... on issueSocialMediaAccount_instagramAccount_BlockType {
            instagramName
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
