import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query IssuePage($slug: [String]) {
    categories(groupId: 3, level: 1, slug: $slug) {
      id
      slug
      title
      ... on issues_Category {
        issueIcon
        featuredContent {
          ... on featuredContent_entry_BlockType {
            ...featuredContent
          }
          ... on featuredContent_externalContent_BlockType {
            ...featuredExternal
          }
        }
        body
        issueTeam {
          ... on team_team_Entry {
            team {
              ... on staff_staff_Entry {
                ...Staff
              }
            }
          }
        }
        issueSocialMediaAccount {
          ... on issueSocialMediaAccount_twitterAccount_BlockType {
            twitterHandle
          }
          ... on issueSocialMediaAccount_instagramAccount_BlockType {
            instagramName
          }
        }
      }
    }
    subscriptionForms: entries(section: "subscriptionForms") {
      ... on subscriptionForms_subscriptionForms_Entry {
        subscriptionFormUrl
        subscriptionFormIssues {
          id
          slug
        }
      }
    }
  }

  fragment featuredExternal on featuredContent_externalContent_BlockType {
    description
    issue {
      id
      slug
      title
    }
    image {
      id
      title
      url
    }
    externalUrl
    externalName
    externalType
  }

  fragment featuredContent on featuredContent_entry_BlockType {
    entry {
      __typename
      id
      postDate
      slug
      title
      uri
      ... on events_events_Entry {
        eventStartDate
        eventIssue {
          id
          slug
          title
        }
        eventPhotos {
          id
          url
          title
        }
      }
      ... on inTheNews_inTheNews_Entry {
        postDate
        publicationName
        publicationLogo {
          url
          id
        }
        newsItemUrl
        newsItemFeaturedImage {
          id
          url
          title
        }
        issues {
          id
          slug
          title
        }
      }
      ... on products_products_Entry {
        ...product
      }
    }
  }

  fragment product on products_products_Entry {
    productBlurb
    productReadingTime
    productAuthors {
      __typename
      id
      slug
      title
      ... on staff_staff_Entry {
        ...Staff
      }
      ... on externalAuthors_externalAuthors_Entry {
        twitterHandle
        position
        photo {
          id
          url
        }
      }
    }
    productFeaturedImage {
      id
      url
      title
    }
    productFeaturedImageUrl
    productIssue {
      id
      slug
      title
    }
    productType {
      id
      slug
      title
    }
  }

  ${StaffFragment}
`;
