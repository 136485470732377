import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query IssuePageRelatedProducts(
    $limit: Int,
    $offset: Int,
    $filters: [QueryArgument],
    $section: [String],
    $parentID: [QueryArgument]
  ) {
    totalCount: entryCount(
      relatedToCategories: [{id: $parentID}],
      public: true,
      section: $section,
      relatedTo: $filters,
    )

    relatedProducts: entries(
      limit: $limit,
      offset: $offset,
      orderBy: "postDate desc",
      public: true,
      relatedToCategories: [{id: $parentID}],
      relatedTo: $filters,
      section: $section,
    ) {
      id
      postDate
      slug
      title
      uri

      ... on products_products_Entry {
        productBlurb
        productReadingTime
        productAuthors {
          __typename
          id
          slug
          title

          ... on staff_staff_Entry {
            ...Staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }

        productFeaturedImage {
          id
          url
          title
        }

        productFeaturedImageUrl

        productIssue {
          id
          slug
          title
        }

        productType {
          id
          slug
          title
        }
      }

      ... on series_series_Entry {
        seriesBlurb

        seriesFeaturedImage {
          url
          id
        }

        issues {
          slug
          title
          id
        }

        authors {
          __typename
          id
          slug
          title

          ... on staff_staff_Entry {
            ...Staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }
      }

      ... on events_events_Entry {
        eventStartDate

        eventIssue {
          slug
          title
          id
        }

        eventPhotos {
          url
          id
        }
      }

      ... on pressReleases_pressReleases_Entry {

        issues {
          id
          slug
          title
        }
      }

      ... on inTheNews_inTheNews_Entry {
        publicationName
        newsItemUrl

        newsItemFeaturedImage {
          url
          id
        }

        publicationLogo {
          url
          id
        }

        issues {
          title
          id
        }
      }
    }
  }

  ${StaffFragment}
`;
