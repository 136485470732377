import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query AboutPageBioQuery($slug: [String]) {
    staff: entry(type: "staff", slug: $slug, previousStaffMember: false) {
      id
      slug
      title

      ... on staff_staff_Entry {
        ...Staff
      }
    }

    products: entries(
      section: "products",
      public: true,
      relatedToEntries: [{slug: $slug}]
    ) {
      id
    }

    media: entries(
      section: "inTheNews",
      public: true,
      relatedToEntries: [{slug: $slug}]
    ) {
      id
    }
  }

  ${StaffFragment}
`;
