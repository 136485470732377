import gql from 'graphql-tag';

export default gql`
  query {
    globalSets(handle: "companyInfo") {
			... on companyInfo_GlobalSet {
        globalFacebook
        globalInstagram
        globalLinkedin
        globalTwitter
        globalYoutube
      }
    }
  }
`;
