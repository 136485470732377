import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import formatDate from '../../utils/formatDate';
import formatDuration from '../../utils/formatDuration';

import './SectionLabel.css';

/**
 * A label that sits above a section.
 */
class SectionLabel extends Component {
  static propTypes = {
    /**
     * Simple display date.
     */
    date: PropTypes.string,
    /**
     * The expected duration of engagement in seconds.
     */
    engagement: PropTypes.string,
    /**
     * The section name.
     */
    name: PropTypes.string.isRequired,
    /**
     * Publish date.
     */
    publishedAt: PropTypes.number,
    /**
     * Updated date.
     */
    updatedAt: PropTypes.number,
  };

  static defaultProps = {
    engagement: null,
    publishedAt: null,
    updatedAt: null,
  };

  render() {
    const {
      date,
      engagement,
      name,
      publishedAt,
      updatedAt,
    } = this.props;

    return(
      <div className={classNames('SectionLabel', this.props.className)}>
        <div className="name">{name}</div>
        {date &&
          <div className="date">{formatDate(date)}</div>
        }
        {publishedAt &&
          <div className="published-at">Published {formatDate(publishedAt)}</div>
        }
        {updatedAt &&
          <div className="updated-at">Updated {formatDate(updatedAt)}</div>
        }
        {formatDuration(engagement) &&
          <div className="engagement">{formatDuration(engagement)} read</div>
        }
      </div>
    );
  }
}

export default SectionLabel;
