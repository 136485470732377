import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, get } from 'lodash-es';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import IssuesRelatedComponent from '../../components/IssuesRelated';

import query from './query';

class IssuesRelated extends Component {
  static propTypes = {
    issueIDs: PropTypes.array,
  };

  render() {
    const { data } = this.props;
    const { error, loading, categories, relatedEntries } = data;

    if (loading) { return null; }
    if (error) { return (<Error error={error} />); }

    const tags = [];

    categories.forEach((category) => {
      const tag = {
        count: relatedEntries.filter(entry => entry.issues.some(issue => issue.id === category.id)).length,
        id: category.id,
        slug: category.slug,
        title: category.title,
        uri: category.uri,
      };

      tags.push(tag);
    });

    tags.sort((a, b) => b.count - a.count);

    return (
      <IssuesRelatedComponent
        tags={tags}
      />
    );
  }
}

export default graphql(query, {
  options: ({ issueIDs }) => ({
    variables: { issueIDs },
  }),
})(IssuesRelated);
