import gql from 'graphql-tag';

const relatedFields = `
  id
  slug
  sectionHandle
  __typename

  ...on events_events_Entry {
    title
    uri
    eventStartDate
    issues: eventIssue {
      id
      slug
      title
    }
    eventPhotos {
      id
      url
      title
    }
  }

  ...on products_products_Entry {
    title
    uri
    postDate
    productFeaturedImageUrl
    productPublicationUpdateDate
    productReadingTime
    issues: productIssue {
      slug
      title
      id
    }
    productFeaturedImage {
      id
      title
      url
    }
    productType {
      slug
      id
      title
    }

    productAuthors {
      ...staff
      ...external
    }

    energyTechnology {
      title
      id
    }
  }

  ...on pressReleases_pressReleases_Entry {
    title
    uri
    postDate
    issues {
      title
      id
    }

    energyTechnology {
      title
      id
    }
  }

  ...on externalContent_default_Entry {
    externalName: title
    externalUrl: newsItemUrl
    externalType: publicationName
    image: newsItemFeaturedImage {
      url
      id
    }

    energyTechnology {
      title
      id
    }
  }

  ...on inTheNews_inTheNews_Entry {
    title
    uri: newsItemUrl

    publicationName
    issues {
      title
      id
    }
    newsItemFeaturedImage {
      url
      id
    }
    publicationLogo {
      url
      id
    }
    energyTechnology {
      title
      id
    }
  }
`;

const fragmentFields = `
  fragment staff on staff_staff_Entry {
    __typename
    id
    title
    uri
    position
    photo {
      url
      id
    }
  }

  fragment external on externalAuthors_externalAuthors_Entry {
    __typename
    id
    title
    uri
    position
    photo {
      url
      id
    }
  }
`;

export const RelatedProductsProducts = gql`
  fragment RelatedProductsProducts on products_products_Entry {
    relatedProducts{
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export const RelatedMapProducts = gql`
  fragment RelatedMapProducts on map_map_Entry {
    relatedContent {
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export const RelatedProductsSeries = gql`
  fragment RelatedProductsSeries on series_series_Entry {
    relatedProducts{
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export const RelatedProductsSeriesGroup = gql`
fragment RelatedProductsSeriesGroup on seriesGroups_group_Entry {
  relatedProducts{
    ${relatedFields}
  }
}

${fragmentFields}
`;

export const RelatedProductsPressReleases = gql`
  fragment RelatedProductsPressReleases on pressReleases_pressReleases_Entry {
    relatedProducts{
      ${relatedFields}
    }
  }

  ${fragmentFields}
`;

export default {
  RelatedProductsSeries,
  RelatedProductsProducts,
  RelatedProductsPressReleases,
};
