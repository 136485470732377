import gql from 'graphql-tag';

export default gql`
  query AboutPageBioEntriesQuery(
    $limit: Int,
    $offset: Int,
    $relatedElement: [QueryArgument],
    $slug: [String],
    $section: [String],
  ) {
    staff: entries(slug: $slug, type: "staff") {
      __typename
      id
      slug
      title
    }

    totalCount: entryCount(
      public: true,
      relatedTo: $relatedElement,
      section: $section
    )

    relatedEntries: entries(
      limit: $limit,
      offset: $offset,
      orderBy: "postDate desc",
      public: true,
      relatedTo: $relatedElement
      section: $section,
    ) {
      id
      postDate
      slug
      title
      uri

      ... on products_products_Entry {
        productReadingTime
        productIssue {
          id
          slug
          title
        }

        productFeaturedImage {
          url
          id
        }

        productFeaturedImageUrl

        productType {
          id
          slug
          title
        }

        productAuthors {
          ...staff
          ...external
        }
      }

      ... on series_series_Entry {
        seriesBlurb

        seriesFeaturedImage {
          url
          id
        }

        issues {
          slug
          title
          id
        }

        authors {
          ...staff
          ...external
        }
      }

      ... on inTheNews_inTheNews_Entry {
        publicationName
        newsItemUrl

        newsItemFeaturedImage {
          url
          id
        }

        publicationLogo {
          url
          id
        }

        issues {
          title
          id
        }

        authors {
          ...staff
          ...external
        }
      }
    }
  }

  fragment staff on staff_staff_Entry {
    __typename
    id
    title
    uri
    position
    photo {
      url
      id
    }
  }

  fragment external on externalAuthors_externalAuthors_Entry {
    __typename
    id
    title
    uri
    position
    photo {
      url
      id
    }
  }
`;
