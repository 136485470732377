import gql from 'graphql-tag';

export default gql`
  query CultureQuery($slug: [String]) {
    culture: entry(slug: $slug) {
      id
      slug
      title
      ... on culture_culture_Entry {
        cultureBody
      }
    }
  }
`;
