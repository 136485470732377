import { get, map } from 'lodash-es';

import fallbackImage from './fallbackImage';

// This utility can construct a standardized item from any of the following types:
// 1. Entry - Products
// 2. Entry - Events
// 3. Entry - InTheNews
// 4. Entry - PressReleases
// 5. Entry - External Content
// 6. ExternalContent
export const itemFromEntry = (entry) => {
  if (!entry) {
    return null;
  }

  if (entry.__typename === 'featuredContent_externalContent_BlockType' || entry.__typename === 'eventRelatedContent_externalContent_BlockType') {
    return itemFromExternalContent(entry);
  }

  // Depending on the query, we either get back an entry object or an entry
  // array with one member. By checking for which of these we have, we can
  // deal with either.
  const item = get(entry, 'entry[0]') ? get(entry, 'entry[0]') : entry;

  // Return the correct object transformation based on the entry's typename.
  if (item.__typename === 'products_products_Entry') {
    return itemFromProduct(item);
  }
  if (item.__typename === 'series_series_Entry') {
    return itemFromSeries(item);
  }
  if (item.__typename === 'inTheNews_inTheNews_Entry') {
    return itemFromInTheNews(item);
  }
  if (item.__typename === 'events_events_Entry') {
    return itemFromEvent(item);
  }
  if (item.__typename === 'pressReleases_pressReleases_Entry') {
    return itemFromPressRelease(item);
  }
  if (item.__typename === 'externalContent_default_Entry') {
    return itemFromExternalContent(item);
  }

  return null;
};

// Returns a standardized list of entries
export const itemsFromEntries = (entries = []) => {
  return map(entries, (entry) => itemFromEntry(entry));
};

export const itemFromEvent = (event) => {
  const id = parseInt(event.id);
  const eventStartDate = event.eventStartDate;
  const itemBlurb = event.shortDescription;
  const itemIssues = event.issues;
  const itemTitle = event.title;
  const itemUrl = `/events/${event.slug}`;
  const sectionHandle = event.sectionHandle;
  const topicName = 'Event';
  const typename = event.__typename;

  let imageTitle, imageUrl;

  if (event.eventPhotos && event.eventPhotos[0]) {
    imageTitle = event.eventPhotos[0].title;
    imageUrl = event.eventPhotos[0].url;
  } else {
    imageTitle = event.title;
    imageUrl = fallbackImage(
      itemIssues ? itemIssues.map((issue) => issue.slug) : [],
    );
  }

  return {
    eventStartDate,
    id,
    itemBlurb,
    imageTitle,
    imageUrl,
    itemIssues,
    itemTitle,
    itemUrl,
    sectionHandle,
    topicName,
    typename,
  };
};

export const itemFromExternalContent = (externalContent) => {
  const id = parseInt(externalContent.id, 10);
  const itemBlurb = externalContent.description;
  const itemIssues = externalContent.issue;
  const itemTitle = externalContent.externalName;
  const itemUrl = externalContent.externalUrl;
  const sectionHandle = externalContent.sectionHandle;
  const topicName = externalContent.externalType;
  const typename = externalContent.__typename;

  let imageTitle, imageUrl;

  if (externalContent.image[0]) {
    imageTitle = externalContent.image[0].title;
    imageUrl = externalContent.image[0].url;
  } else {
    imageTitle = externalContent.title;
    imageUrl = fallbackImage(
      externalContent.issue
        ? externalContent.issue.map((issue) => issue.slug)
        : [],
    );
  }

  return {
    id,
    imageTitle,
    imageUrl,
    itemBlurb,
    itemIssues,
    itemTitle,
    itemUrl,
    topicName,
    sectionHandle,
    typename,
  };
};

export const itemFromInTheNews = (inTheNews) => {
  const id = parseInt(inTheNews.id, 10);
  const itemAuthors = inTheNews.authors;
  const itemBlurb = inTheNews.blurb;
  const itemIssues = inTheNews.issues;
  const itemPublicationDate = inTheNews.postDate;
  const itemTitle = inTheNews.title;
  const itemUrl = inTheNews.newsItemUrl ? inTheNews.newsItemUrl : inTheNews.uri;
  const topicName = 'Third Way in the news';
  const itemPublicationLogo = get(inTheNews, 'publicationLogo[0].url', null);
  const itemPublicationName = inTheNews.publicationName;
  const sectionHandle = inTheNews.sectionHandle;
  const typename = inTheNews.__typename;

  let imageTitle, imageUrl;

  if (inTheNews.newsItemFeaturedImage && inTheNews.newsItemFeaturedImage[0]) {
    imageTitle = inTheNews.newsItemFeaturedImage[0].title;
    imageUrl = inTheNews.newsItemFeaturedImage[0].url;
  } else {
    imageTitle = inTheNews.title;
    imageUrl = fallbackImage(
      itemIssues ? itemIssues.map((issue) => issue.slug) : [],
    );
  }

  return {
    id,
    imageTitle,
    imageUrl,
    itemAuthors,
    itemBlurb,
    itemIssues,
    itemPublicationDate,
    itemPublicationLogo,
    itemPublicationName,
    itemTitle,
    itemUrl,
    sectionHandle,
    topicName,
    typename,
  };
};

export const itemFromPressRelease = (pressRelease) => {
  const id = parseInt(pressRelease.id, 10);
  const itemIssues = pressRelease.issues;
  const itemTitle = pressRelease.title;
  const itemPublicationDate = pressRelease.postDate;
  const itemUrl = `/press/${pressRelease.slug}`;
  const sectionHandle = pressRelease.sectionHandle;
  const topicName = 'Press Release';
  const typename = pressRelease.__typename;

  const imageTitle = pressRelease.title;
  const imageUrl = fallbackImage(
    itemIssues ? itemIssues.map((issue) => issue.slug) : [],
  );

  return {
    id,
    imageTitle,
    imageUrl,
    itemIssues,
    itemPublicationDate,
    itemTitle,
    itemUrl,
    sectionHandle,
    topicName,
    typename,
  };
};

export const itemFromProduct = (product) => {
  const id = parseInt(product.id, 10);
  const itemAuthors = product.productAuthors;
  const itemBlurb = product.productBlurb;
  const itemIssues = product.issues;
  const itemPublicationDate = product.productPublicationUpdateDate
    ? product.productPublicationUpdateDate
    : product.postDate;
  const itemReadingTime = product.productReadingTime;
  const itemTitle = product.title;
  const itemUrl = product.uri
    ? `/${product.uri}`
    : `/${product.productType[0].slug}/${product.slug}`;
  const sectionHandle = product.sectionHandle;
  const topicName =
    product.productType && product.productType[0]
      ? product.productType[0].title
      : '';
  const typename = product.__typename;

  let imageTitle, imageUrl;

  if (product.productFeaturedImage && product.productFeaturedImage[0]) {
    imageTitle = product.productFeaturedImage[0].title;
    imageUrl = product.productFeaturedImage[0].url;
  } else if (product.productFeaturedImageUrl) {
    imageTitle = product.title;
    imageUrl = product.productFeaturedImageUrl;
  } else {
    imageTitle = product.title;
    imageUrl = fallbackImage(
      itemIssues ? itemIssues.map((issue) => issue.slug) : [],
    );
  }

  return {
    id,
    imageTitle,
    imageUrl,
    itemAuthors,
    itemBlurb,
    itemIssues,
    itemPublicationDate,
    itemReadingTime,
    itemTitle,
    itemUrl,
    sectionHandle,
    topicName,
    typename,
  };
};

export const itemFromSeries = (series) => {
  const id = parseInt(series.id, 10);
  const itemAuthors = series.authors;
  const itemBlurb = series.seriesBlurb;
  const itemIssues = series.issues;
  const itemPublicationDate = series.postDate;
  const itemTitle = series.title;
  const itemUrl = `/${series.uri}`;
  const sectionHandle = series.sectionHandle;
  const topicName = 'Series';
  const typename = series.__typename;

  let imageTitle, imageUrl;

  if (series.seriesFeaturedImage && series.seriesFeaturedImage[0]) {
    imageTitle = series.seriesFeaturedImage[0].title;
    imageUrl = series.seriesFeaturedImage[0].url;
  } else {
    imageTitle = series.title;
    imageUrl = fallbackImage(
      itemIssues ? itemIssues.map((issue) => issue.slug) : [],
    );
  }

  return {
    id,
    imageTitle,
    imageUrl,
    itemAuthors,
    itemBlurb,
    itemIssues,
    itemPublicationDate,
    itemTitle,
    itemUrl,
    series,
    topicName,
    typename,
  };
};

export const itemsFromRelated = (items) => {
  const label = {
    InTheNews: 'In The News',
    PressReleases: 'Press Release',
  };

  return items.map((item) => {
    return {
      ...item,
      ...(item.productType && { label: item.productType[0].title }),
      ...(!item.productType &&
        label[item.__typename] && { label: label[item.__typename] }),
      ...(item.newsItemUrl && { uri: item.newsItemUrl }),
    };
  });
};

export default itemFromEntry;
