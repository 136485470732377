import React, { Component } from 'react';
import _, { get, uniq } from 'lodash-es';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import IssueFiltersComponent from '../../components/IssueFilters';
import withIssueFilter from '../../wrappers/withIssueFilter';
import withContentFilters from '../../wrappers/withContentFilters';
import withSearch from '../../wrappers/withSearch';

import query from './query';

// The query for this is different enough that we have to have a separate container
// from the standard IssueFilters just for the SeriesPage. Unfortunate.
class SeriesPageIssueFilters extends Component {
  constructor() {
    super();

    this.onShowLess = this.onShowLess.bind(this);
    this.onShowMore = this.onShowMore.bind(this);

    this.state = {
      showMore: false,
    };
  }

  onShowLess() {
    this.setState({ showMore: false });
  }

  onShowMore() {
    this.setState({ showMore: true });
  }

  render() {
    const { data, issueFilter, contentTypeFilter, team} = this.props;
    const { error, loading } = data;

    if (loading) { return null; }
    if (error) { return (<Error error={error} />); }

    const entries = data.entry.seriesGroups.length > 0
      ? get(data, 'entry.seriesGroups').map((group) => group.relatedProducts).flat()
      : get(data, 'entry.relatedProducts');

    contentTypeFilter.availableTypes = uniq(entries.map((entry) => entry.sectionHandle.toLowerCase()));

    if (!entries || !entries.length > 0) { return null; }

    const tags = _.chain(entries).map(item => item.issues)
                               .flatten()
                               .groupBy('title')
                               .map((items, title) => ({
                                  title,
                                  count: items.length,
                                  id: items[0].id,
                                  slug: items[0].slug,
                                }))
                               .sort((a, b) => b.count - a.count)
                               .value();

    return (
      <IssueFiltersComponent
        filters={["issues", "contentType"]}
        issueFilter={issueFilter}
        contentTypeFilter={contentTypeFilter}
        showMore={this.state.showMore}
        onShowLess={this.onShowLess}
        onShowMore={this.onShowMore}
        team={team}
        tags={tags}
        totalCount={entries.length}
      />
    );
  }
}

const withGraphql = graphql(query, {
  options: ({ match: { params } }) => ({
    variables: { slug: params.slug },
  }),
})(SeriesPageIssueFilters);

export default withContentFilters(withIssueFilter(withSearch(withGraphql)));
