import React, { useState } from 'react';
import Content from '../Content';
import PaginatedEntry from '../PaginatedEntry';
import './SeriesGroup.css'
import Button from '../Button';

const SeriesGroup = ({group}) => {
    const items = group.relatedProducts;
    const [limit, setLimit] = useState(6);
    const visibleItems = items.slice(0, limit);

    return (
        <div className="series-group">
            <h2 className="group-heading">{group.title}</h2>
            {group.description &&
                <Content className="group-description" content={group.description} />
            }
            <div className="group-items">
                {visibleItems.map((item) =>
                    <PaginatedEntry
                    key={item.id}
                    item={item}
                    displayThumbnail={true}
                    headingLevel={'h3'}
                    />
                )}
                {items.length > 6 && (
                    <div className="series-button">
                        <Button dark onClick={() => setLimit(visibleItems.length < items.length ? items.length : 6)}>
                            {visibleItems.length < items.length ? 'Show More' : 'Show Less'}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SeriesGroup;
