import React from 'react';
import { filter, get } from 'lodash-es';
import { graphql } from 'react-apollo';

import ContactPageComponent from '../../components/ContactPage';
import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';

import query from './query';

const ContactPage = ({ data }) => {
  const { categories, error, loading, companyInfo } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (error) { return (<Error error={error} />); }

  const eventContact = companyInfo[0].eventContact[0];
  const pressContact = companyInfo[0].pressContact[0];
  const filteredIssues = filter(categories, (category => category.issueSocialMediaAccount &&
                                                         category.issueSocialMediaAccount.length > 0 &&
                                                         !category.issueHidden));
console.log(filteredIssues);

  return (
    <ContactPageComponent
      issues={filteredIssues}
      companyInfo={companyInfo[0]}
      eventContact={eventContact}
      pressContact={pressContact}
    />
  );
};

ContactPage.propTypes = {
  data: dataShape,
};

export default graphql(query)(ContactPage);
