import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { kebabCase } from 'lodash-es';
import TechnologyIcon from '../../TechnologyIcon';
import arrowDown from '../../../../icons/arrow-down.svg';
import arrowUp from '../../../../icons/arrow-up.svg';
import './CepProjectDetails.css';

function parseInvestment(amount) {
  const num = amount ? parseInt(amount.replace('$', '').replaceAll(',', ''), 10) : null;

  return num;
}

const CepProjectDetails= ({
  projects,
  activeProject,
  selectProject,
  hoveredProject,
  energyTechnology,
}) => {
  const wrapperRef = useRef(null);
  const projectsRef = useRef([]);
  const scrollInterval = useRef(null);
  const scrollAmount = 10;
  const scrollSpeed = 10;
  const scrollOptions = {
    top: scrollAmount,
    behavior: 'instant'
  };
  const [technologyPages, setTechnologyPages] = useState({});
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    compactDisplay: 'long',
    roundingPriority: 'morePrecision'
  });

  useEffect(() => {
    const pages = {};

    energyTechnology.forEach((tech) => {
      if (tech.technologyPage.length) {
        pages[tech.technologyId] = tech.technologyPage[0].uri ? tech.technologyPage[0].uri : '';
      }
    });

    setTechnologyPages(pages);
  }, [energyTechnology])

  useEffect(() => {
    if (activeProject) {
      // TODO: Marker style changes triggered here?
      projectsRef.current[activeProject].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [activeProject]);

  const startScroll = (dir) => {
    scrollOptions.top = dir === 'down' ? scrollAmount : scrollAmount * -1;

    if (scrollInterval.current) return;

    scrollInterval.current = setInterval(() => {
      if(wrapperRef.current) {
        wrapperRef.current.scrollBy(scrollOptions);
      }
    }, scrollSpeed);
  }

  const stopScroll = () => {
    if (scrollInterval.current) {
      clearInterval(scrollInterval.current);
      scrollInterval.current = null;
    }
  }

  return(
    <div className="project-details-container center-map-ui">
      <div className="details-wrapper" ref={wrapperRef}>
        <ul className="details-list">
          {projects.map((project) => {
            const investment = project["Investment Amount"];
            const id = project["Id"].toString();
            const technologyTitles = project["Technology Titles"].length ? project["Technology Titles"] : [];
            const techIds = project["Technology IDs"] ? project["Technology IDs"].map((id) => parseInt(id.replace(',', ''), 10)) : [];
            const sources = [];

            if (project["Announcement Text"] && project["Announcement"]) {
              sources.push({
                text: project["Announcement Text"],
                url: project["Announcement"],
              });
            }

            if (project["Additional Resource Link Text"] && project["Additional Resource Link"]) {
              sources.push({
                text: project["Additional Resource Link Text"],
                url: project["Additional Resource Link"],
              });
            }

            return (
              <li
                className={classNames(
                  'project',
                  id === activeProject ? 'active' : '',
                  id === hoveredProject ? 'hover' : ''
                )}
                key={id}
                ref={el => projectsRef.current[id] = el}
              >
                <button
                  className="project-expand"
                  aria-expanded={activeProject !== id}
                  aria-controls={`project-${id}`}
                  onClick={() => selectProject(activeProject !== id ? id : null)}
                >
                  <div className="project-icon">
                    <TechnologyIcon slug={technologyTitles.length ? kebabCase(technologyTitles[0]) : ''} />
                  </div>
                  <h3 className="project-name" id={`detail-label-${id}`}>
                    {project["Project Name"]}
                  </h3>
                </button>
                <div
                  className="project-details"
                  id={`project-${id}`}
                  aria-labelledby={`detail-label-${id}`}
                  role="region"
                >
                  <dl>
                    <div className="detail">
                      <dt>Technology Type</dt>
                      <dd>
                        {techIds.map((techId, i) => {
                          let typeEl = '';

                          if (technologyPages[techId]) {
                            typeEl = <span key={i}><a href={technologyPages[techId]}>{technologyTitles[i]}{i < techIds.length - 1 ? ', ' : ''}</a></span>
                          } else {
                            typeEl = <span key={i}>{technologyTitles[i]}{i < techIds.length - 1 ? ', ' : ''}</span>;
                          }

                          return (typeEl);
                      })}
                      </dd>
                    </div>
                    <div className="detail">
                      <dt>Jobs Created</dt>
                      <dd>{project["Job Creation Projection"]}</dd>
                    </div>
                    <div className="detail">
                      <dt>Congressional District</dt>
                      <dd>{project["Congressional District"]}</dd>
                    </div>
                    <div className="detail">
                      <dt>Investment Amount</dt>
                      <dd>{(parseInvestment(investment) > 999999) ? currency.format(parseInvestment(project["Investment Amount"])) : investment}</dd>
                    </div>
                    <div className="detail">
                      <dt>State</dt>
                      <dd>{project["State"]}</dd>
                    </div>
                    <div className="detail">
                      <dt>County</dt>
                      <dd>{project["County"]}</dd>
                    </div>
                    <div className="detail">
                      <dt>Zip</dt>
                      <dd>{project["Zipcode"]}</dd>
                    </div>
                    <div className="detail">
                      <dt>Source</dt>
                      <dd>
                        {sources.map((source, i) => {
                          return <span key={i}><a href={source.url} key={i}>{source.text}</a>{i < sources.length - 1 ? ',     ' : ''}</span>
          })}
                      </dd>
                    </div>
                  </dl>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="project-details-scrollers">
        <button
          className="project-details-scroller"
          aria-label="scroll projects up"
          onMouseDown={(e) => startScroll('down')}
          onMouseUp={() => stopScroll()}
          onMouseLeave={() => stopScroll()}
        >
          <ReactSVG src={arrowDown} />
        </button>
        <button
          className="project-details-scroller"
          aria-label="scroll projects down"
          onMouseDown={() => startScroll('up')}
          onMouseUp={() => stopScroll()}
          onMouseLeave={() => stopScroll()}
        >
          <ReactSVG src={arrowUp} />
        </button>
      </div>
    </div>
  );
};

export default CepProjectDetails;
