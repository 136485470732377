import React, { useEffect, useRef, useState } from 'react';
import stateData from './us-states.json';
import stateAbbreviationData  from './state-abbreviations.json';
import fallbackProjectsData from './placeholderData/projects.json';
import fallbackTechnologyData from './placeholderData/technologies.json';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { uniq, uniqBy } from 'lodash-es';
import CepUsStateFilter from './CepUsStateFilter';
import CepProjectDetails from './CepProjectDetails';
import './CEPMap.css';
import useScreenSize from '../../../utils/useScreenSize';

import { getMapIconElement } from '../TechnologyIcon';
import Content from '../../Content';

const CepMap = ({caption, snapshots, setActiveSnapshot, energyTechnology , selectedCategoryFilter, setSelectedCategoryFilter}) => {
    const { isLoaded } = new useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });
    const googleCloudMapId = "e325c308683986e";
    // Default map center coordinates (~Omaha)
    const originalCenter = {
        lat: 41.405791935764654,
        lng: -95.51527646746152,
    };
    const defaultMapStyles = {
        "strokeWeight": 0.2,
        "strokeColor": '#2b2b2b',
        "fillColor": '#887923',
        "fillOpacity": 0,
    };
    const highlightStateMapStyle = {
        fillOpacity: 0.1,
        strokeWeight: 2
    };
    // ID values for NocoDB Tables and Views
    const nocoApiProjectsTable = 'm78neztyag8nvlk';
    const nocoApiProjectsView = 'vwwxl6b1g94xu6pr';
    const nocoApiTechnologyTable = 'mqsy5vir20a3g8w';
    const nocoApiTechnologyView = 'vwmsc5blor8ogm8l';


    const mapElRef = useRef(null);
    const googleRef = useRef(null);
    const markersRef = useRef([]);
    const currentSelectedStateFeatureRef = useRef(null);
    const formattedSnapshots = uniqBy(snapshots, 'id').map((snapshot) => {
        return {
            "State": snapshot.state,
            "GeoData": snapshot.mapCoordinates,
            "Id": snapshot.id,
            "Technology Titles": snapshot.energyTechnology.map((tech) => tech.title),
            techIds: snapshot.energyTechnology.map((tech) => parseInt(tech.technologyId, 10)),
            isSnapshot: true,
        }
    });

    const [map, setMap] = useState();
    const [center, setCenter] = useState(originalCenter);
    const [selectedUsState, setSelectedUsState] = useState(false);
    const [allProjects, setAllProjects] = useState([]);
    const [allTechnologies, setAllTechnologies] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [filteredSnapshots, setFilteredSnapshots] = useState([]);
    const [activeProject, setActiveProject] = useState(null);
    const [hoveredProject, setHoveredProject] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);
    const [technologyTitlesMap, setTechnologyTitlesMap] = useState([]);
    const screenSize = useScreenSize();

    const nocoApiToken = process.env.REACT_APP_NOCODB_API_TOKEN;
    const nocoApiBaseUrl = process.env.REACT_APP_NOCODB_API_BASE_URL;

    const onLoad = React.useCallback(function callback(mapInstance) {
        googleRef.current = window.google;
        setMap(mapInstance);
        initData();

        // Add a click listener to the map to detect clicks that are not on markers or GeoJSON shapes
        mapInstance.addListener('click', () => {
            resetMap();
        });
    }, [ allProjects, allTechnologies]);

    async function initData() {
        try {
            // TODO: Refine fallbacks and async
            const projectsResponse = await getProjects();
            const technologiesResponse = await getTechnologies();

            setAllProjects(projectsResponse.list);
            setAllTechnologies(technologiesResponse.list);
        } catch (err) {
            console.error('Unable to connect to nocodb. Using fallback data. Error: ', err);
            setAllTechnologies(fallbackTechnologyData);
            setAllProjects(fallbackProjectsData);
        }
    }

    async function getProjects() {
        const url = nocoApiBaseUrl + '/api/v2/tables/' + nocoApiProjectsTable + '/records?viewId=' + nocoApiProjectsView + '&offset=0&shuffle=0&limit=1000';
        const options = {
            method: 'GET',
            headers: {'xc-token': nocoApiToken}
        };

        const response = await fetch(url, options);

        if (!response.ok) {
            console.error('Error status - GetData:', response.status);
            throw new Error('Network response was not ok');
        }

        return await response.json();
    }

    async function getTechnologies() {
        const url =  nocoApiBaseUrl + '/api/v2/tables/' + nocoApiTechnologyTable + '/records?viewId=' + nocoApiTechnologyView + '&limit=50&shuffle=0&offset=0';
        const options = {
            method: 'GET',
            headers: {'xc-token': nocoApiToken}
        };

        const response = await fetch(url, options);

        if (!response.ok) {
            console.error('Error status - GetTechnologies:', response.status);
            throw new Error('Network response was not ok');
        }

        return await response.json();
    }

    async function initMap() {
        const google = googleRef.current;

        // Add the geographical JSON data to the map and set its visual style
        map.data.addGeoJson(stateData);
        map.data.setStyle(defaultMapStyles);

        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
        const markerData = [...allProjects, ...formattedSnapshots];

        // Initialize the filtered projects array with all projects
        setFilteredProjects(allProjects);
        setFilteredSnapshots(formattedSnapshots);

        // Loop through each project to retrieve data and add markers to the map
        markerData.forEach(async (project) => {
            // The column identifies must match the string in the nocodb table
            const id = Number(project['Id']);
            const projectTypes = project['Technology Titles'];
            const state = project['State'];
            // const techIds = project['Technology Ids'];
            const isSnapshot = project.isSnapshot;

            // If there isn't an associated technologyId do not add a marker and return early
            if (projectTypes.length === 0) return;

            let geoDataArray = [];
            let lat = 0;
            let lng = 0;

            // Parse latitude and longitude strings to float for mapping
            if (project.hasOwnProperty('GeoData') && project['GeoData']) {
                if (isSnapshot) {
                    geoDataArray = project['GeoData'].split(',');
                } else {
                    geoDataArray = project['GeoData'].split(';');
                }
                lat = parseFloat(geoDataArray[0]);
                lng = parseFloat(geoDataArray[1]);
            } else {
                // No GeoData for Marker, exit early
                return;
            }

            // Invalid lat or lng, exit early
            if (
                typeof lat !== 'number'
                || typeof lng !== 'number'
                || isNaN(lat)
                || isNaN(lng)
            ) return;

            // Pass true to isSnapshot to change the styles to the snapshot styles
            const marker = new AdvancedMarkerElement({
                map,
                position: { lat, lng },
                content: getMapIconElement(projectTypes[0], isSnapshot),
                title: id.toString()
            })

            marker.tech = projectTypes.map(tech => tech.trim());
            // marker.technologyIds = techIds;
            marker.state = state;
            marker.isSnapshot = project.isSnapshot;
            marker.id = id;

            // Push the marker in with ID reference
            markersRef.current[marker.id] = marker;

            marker.addListener("click", (e) => {
                if (isSnapshot) {
                    setActiveSnapshot(id);
                } else {
                    setActiveProject(marker.title);
                    const svg = e.domEvent.target.closest('[class*="-marker-view"]')

                    document.querySelectorAll('[class*="-marker-view"]').forEach((marker) => marker.classList.remove('active'))

                    if (svg) {
                        svg.classList.add('active')
                    }
                }
            });
        });

        map.data.addListener('mouseout', function(event) {
            map.data.revertStyle();

            if (currentSelectedStateFeatureRef.current) {
                map.data.overrideStyle(currentSelectedStateFeatureRef.current, highlightStateMapStyle);
            }
        });
    }

    // Reset map zoom, marker visibility, selected state and tech
    function resetMap(clearFilters = true) {
        if (map) {
            if (!selectedUsState || clearFilters) {
                map.setZoom(4);
                setCenter(originalCenter);
                map.data.revertStyle();
                currentSelectedStateFeatureRef.current = null;
            }

            if (clearFilters) {
                clearSelectedStateFilter();
                clearSelectedTechnologyCategory();
                setFilteredProjects(allProjects);
                setFilteredSnapshots(formattedSnapshots);
            }
        }
    }

    // Utility function to fly to Lan/Lng position
    function zoomLatLng(latLng, zoomLevel = 6) {
        // Set the center of the map to the passed Lat/Lng object and zoom in
        // TODO: Dynamic zoom level?
        const zoomTimeout = setTimeout(() => {
            setCenter(latLng);
            map.setZoom(zoomLevel);
        }, 100);
    }

    // Recursively process GeoJSON geometry into extended LatLng bounds
    // Process geometry sets of lat/lng points onto individual points
    // Then execute the callback to extend the lat/lng bounds
    // of the passed bounds = new google.maps.LatLngBounds();
    function processPoints(geometry, extendsCallback, latLnBounds) {
        if (geometry instanceof googleRef.current.maps.LatLng) {
            extendsCallback.call(latLnBounds, geometry);
        } else if (geometry instanceof googleRef.current.maps.Data.Point) {
            extendsCallback.call(latLnBounds, geometry.get());
        } else {
            geometry.getArray().forEach(function(g) {
                processPoints(g, extendsCallback, latLnBounds);
            });
        }
    }

    // Filter visible projects by given state Abbr
    function filterStateByAbbreviation(stateAbbr) {
        const google = googleRef.current;

        // TODO: Exit early or reset if null/false/etc?

        // Reset any applied feature styles when this is called
        map.data.revertStyle();

        setSelectedUsState(stateAbbr);

        // Abbreviation is blank or 'all', show all markers and exit
        if (stateAbbr === '' || stateAbbr === 'all' ) {
            resetMap(false);
            return;
        }

        filterProjects();

        // Get the full state name
        const stateName = getFullStateNameFromAbbreviation(stateAbbr);
        // Get the feature object of the GeoJSON state feature we want to find in the map data
        const filteredFeatures = stateData.features.filter((feature) => {
            return feature.properties.name === stateName;
        });
        const targetFeatureId = filteredFeatures[0].id;

        // Find the map data target feature with matching ID
        let targetFeature;
        map.data.forEach((feature) => {
            if (feature.getId() === targetFeatureId) {
                targetFeature = feature;
                return;
            }
        })

        map.data.overrideStyle(targetFeature, highlightStateMapStyle);
        currentSelectedStateFeatureRef.current = targetFeature;

        // Process the features geometry into an extended bounds
        let bounds = new google.maps.LatLngBounds();
        processPoints(targetFeature.getGeometry(), bounds.extend, bounds);

        // // Fit the map boundaries, with a little bit of padding
        const zoomTimeout = setTimeout(() => {
            setCenter(bounds.getCenter());
            map.fitBounds(bounds, 100);
        }, 100);
    }

    // Function to find a full state name based on the passed abbreviation
    function getFullStateNameFromAbbreviation(stateAbbr) {
        return Object.keys(stateAbbreviationData).find(key => stateAbbreviationData[key] === stateAbbr);
    }

    // Reset the selected US State to filter with
    function clearSelectedStateFilter() {
        setSelectedUsState(false);
    }

    function filterProjects() {
        let projects = allProjects;
        let snapshots = formattedSnapshots;

        if (selectedCategoryFilter) {
            projects = projects.filter(project => {
                return project.techIds.includes(selectedCategoryFilter);
            });

            snapshots = snapshots.filter(snapshot => {
                return snapshot.techIds.includes(selectedCategoryFilter);
            });
        }

        if (selectedUsState) {
            projects = projects.filter(project => {
                return project['State'] === selectedUsState;
            });
        }

        setFilteredProjects(projects);
        setFilteredSnapshots(snapshots);
    }

    function filterMapByTechnologyCategory() {
        resetMap(false);
        filterProjects();
    }

    function clearSelectedTechnologyCategory() {
        setSelectedCategoryFilter(null);
    }

    function clearFilters() {
        clearSelectedTechnologyCategory();
        clearSelectedStateFilter();
        resetMap();
    }

    // Use useEffect to watch for changes to selectedState
    useEffect(() => {
        if (selectedUsState) {
            filterStateByAbbreviation(selectedUsState);
        }
    }, [selectedUsState]);

    // Build the setTechnologyTitlesMap object after projects and technologies exists
    useEffect(() => {
        if (allProjects.length && allTechnologies.length) {
            const techTitlesMapObject = allTechnologies.map(tech => ({
                id: tech['Id'],
                title: tech['Title'],
                slug: tech['Title'].toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''),
            }));
            setTechnologyTitlesMap(techTitlesMapObject);

            allProjects.forEach(project => {
                project.techIds = techTitlesMapObject
                    .filter(item => project['Technology Titles'].includes(item.title))
                    .map(item => item.id);
            })
        }
    }, [allProjects, allTechnologies]);

    useEffect(() => {
        if (allProjects.length && allTechnologies.length) {
            // Wait until setTechnologyTitlesMap exists to init map itself
            initMap(allProjects, allTechnologies);

            setStateOptions(uniq([...allProjects, ...formattedSnapshots].map((project) => project["State"])));
        }
    }, [technologyTitlesMap]);

    // Watch for changes to selected technology category
    useEffect(() => {
        if (selectedCategoryFilter || selectedCategoryFilter === '') {
            filterMapByTechnologyCategory(selectedCategoryFilter);
        }
    }, [selectedCategoryFilter]);

    // Changes in filtered projects, re-render markers
    useEffect(() => {
        if ((filteredProjects || filteredSnapshots) && markersRef.current) {
            // Hide all markers
            Object.values(markersRef.current).forEach(marker => {
                marker.map = null;
            });

            // Show only filtered markers
            [...filteredProjects, ...filteredSnapshots].forEach(item => {
                const marker = markersRef.current[item.Id];

                if (marker) {
                    marker.map = map;
                }
            });
        }
    }, [filteredProjects, filteredSnapshots]);

    // Zoom to marker when active project
    useEffect(() => {
        const activeProjectDetails = allProjects.find(project => Number(project.Id) === Number(activeProject));

        // No valid project, exit
        if (!activeProjectDetails) return;

        // TODO: Doing this logic twice in this file, could combine
        // Validate latitude and longitude
        let geoDataArray = [];
        let lat = 0;
        let lng = 0;

        // Parse latitude and longitude strings to float for mapping
        if (activeProjectDetails.hasOwnProperty('GeoData') && activeProjectDetails['GeoData']) {
            geoDataArray = activeProjectDetails['GeoData'].split(';');
            lat = parseFloat(geoDataArray[0]);
            lng = parseFloat(geoDataArray[1]);
        } else {
            // No GeoData for Marker, exit early
            return;
        }

        // Invalid lat or lng, exit early
        if (
            typeof lat !== 'number'
            || typeof lng !== 'number'
            || isNaN(lat)
            || isNaN(lng)
        ) return;

        zoomLatLng({lat, lng});
    }, [activeProject]);

    return (
      <div>
          <div className="map-container" ref={mapElRef} id="cep-map">
              {isLoaded ? (
                <GoogleMap
                  onLoad={onLoad}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  center={center}
                  zoom={4}
                  mapId={googleCloudMapId}
                  options={{
                      "disableDefaultUI": true,
                      "zoomControl": true,
                      "zoomControlOptions": { position: screenSize.width >= 768 ? window.google.maps.ControlPosition.LEFT_BOTTOM : window.google.maps.ControlPosition.RIGHT_TOP },
                      "mapId": googleCloudMapId,
                  }}
                />
              ) : <React.Fragment></React.Fragment>}
              <CepProjectDetails
                projects={filteredProjects}
                activeProject={activeProject}
                selectProject={setActiveProject}
                setHoveredProject={setHoveredProject}
                hoveredProject={hoveredProject}
                energyTechnology={energyTechnology}
              />
              {/* Simple Filter via select onChange Component */}
              <CepUsStateFilter
                states={stateOptions}
                selectedUsState={selectedUsState}
                setSelectedUsState={setSelectedUsState}
                onFilter={filterStateByAbbreviation}
                clearFilters={clearFilters}
              />
              {/* Example calling filterStateByAbbreviation directly  */}
              {/* <button onClick={() => filterStateByAbbreviation('TN')}>Show Only TN</button> */}
          </div>
          <div className="map-caption">
              <Content content={caption} />
          </div>

      </div>
    )
}

export default CepMap;
