import React from 'react';
import classNames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import Imgix from 'react-imgix';
import { get } from 'lodash-es';
import { Link, Redirect } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import AboutPageBioEntries from '../../containers/AboutPageBioEntries';
import AboutPageBioIssueFilters from '../../containers/AboutPageBioIssueFilters';
import AppHelmet from '../AppHelmet';
import Content from '../Content';
import OutboundLink from '../OutboundLink';
import SectionLabel from '../SectionLabel';

import './AboutPageBio.css';

const AboutPageBio = ({ location, staff, totalInTheNews, totalProducts }) => {
  if (!staff) { return null; }

  const imageUrl = get(staff, 'photo[0].url');
  const imageTitle = get(staff, 'photo[0].title') || staff.title;
  const { preventProductDisplay } = staff;

  const navLinks = [];

  if (totalProducts > 0 && !preventProductDisplay) { navLinks.push({ pathname: `/${staff.uri}`, title: 'Products' }); }
  if (totalInTheNews > 0) { navLinks.push({ pathname: `/${staff.uri}/media`, title: 'Media' }); }

  // If we're only displaying one type of entry, no need for the /media route
  if (navLinks.length <= 1 && location.pathname === `/${staff.uri}/media`) { return (<Redirect to={`/${staff.uri}`} />); }

  return (
    <div className="AboutPageBio">
      <AppHelmet title={staff.title || 'About'} />

      <Row>
        <Col xs={12}>
          <SectionLabel className="mb-3" name={staff.position} />
          <div className="title-bar align-baseline mb-4">
            <h1 className="title mr-4">{staff.title}</h1>
            {(staff.email || staff.twitterHandle) &&
              <div className="share-links mb-1">
                {staff.twitterHandle &&
                  <OutboundLink
                    className="twitter-link mr-4"
                    eventLabel={`https://twitter.com/${staff.twitterHandle}`}
                    to={`https://twitter.com/${staff.twitterHandle}`}
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} size="lg" className="mr-2" />
                    @{staff.twitterHandle}
                  </OutboundLink>
                }

                {staff.email &&
                  <a href={`mailto:${staff.email}`} className="email-link">
                    <FontAwesomeIcon icon={['fas', 'envelope']} size="lg" className="mr-2" />
                    Email {staff.title.split(' ')[0]}
                  </a>
                }
              </div>
            }
          </div>
        </Col>
      </Row>

      {(imageUrl || staff.description) &&
        <Row>
          <Col xs={12} className="pb-4">
            {imageUrl &&
              <div className="float-left mr-lg-4 mb-3 mb-sm-4 mb-lg-1 w-100 w-lg-50 bio-image">
                <Imgix
                  aggressiveLoad
                  className="outset inset-lg w-lg-100"
                  crop="faces,center"
                  height={663}
                  imgProps={{ alt: imageTitle }}
                  src={imageUrl}
                  width={992}
                />

                <a className="c-pointer download-bio-image" href={imageUrl} download={imageTitle} target="_blank">Download hi-res version</a>
              </div>
            }

            {staff.description &&
              <Content
                content={staff.description}
              />
            }
          </Col>
        </Row>
      }

      <Row>
        <Col xs={12} md={4} lg={3}>
          <AboutPageBioIssueFilters
            navLinks={navLinks}
            location={location}
          />
        </Col>

        <Col xs={12} md={8} lg={9}>
          <Row>
            <Col xs={12} className="pb-4">
              {navLinks.map((link, i) => (
                <h2
                  className={classNames('bio-nav-link', {
                    active: navLinks.length <= 1 || location.pathname === link.pathname,
                  })}
                  key={i}
                >
                  <Link to={{ pathname: link.pathname, state: { noScroll: true }}}>
                    {link.title}
                  </Link>
                </h2>
              ))}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              {(totalInTheNews > 0 || (totalProducts > 0 && !staff.preventProductDisplay)) &&
                <AboutPageBioEntries
                  navLinks={navLinks}
                  staffID={parseInt(staff.id)}
                />
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AboutPageBio;
