import React from 'react';
import MediaQuery from 'react-responsive';
import compact from 'lodash-es/compact';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import get from 'lodash-es/get';
import has from 'lodash-es/has';
import map from 'lodash-es/map';
import sanitizeHtml from 'sanitize-html';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import AuthorList from '../AuthorList';
import Content from '../Content';
import Divider from '../Divider';
import FeaturedItems from './FeaturedItems';
import IssueFilters from '../../containers/IssueFilters';
import IssuePageRelatedProducts from '../../containers/IssuePageRelatedProducts';
import OutboundLink from '../OutboundLink';
import SectionLabel from '../SectionLabel';
import SubscribeBox from '../../containers/SubscribeBox';
import TwitterFeed from '../../containers/TwitterFeed';

import './IssuePage.css';

const IssuePage = (props) => {
  const { contentTypeFilter, authorFilter, issue, subscriptionUrl } = props;
  let team;

  const twitterHandles = compact(
    map(issue.issueSocialMediaAccount, (account) => {
      if (has(account, 'twitterHandle')) {
        return account.twitterHandle;
      }
    }),
  );

  if (!issue) {
    return null;
  }

  if (issue.issueTeam && issue.issueTeam.length > 0) {
    team = issue.issueTeam[0].team;
  }

  return (
    <div className="IssuePage">
      <AppHelmet
        description={
          get(issue, 'body')
            ? sanitizeHtml(get(issue, 'body'), { allowedTags: [] })
            : undefined
        }
        image={
          issue.slug
            ? `https://thirdway.imgix.net/fallback/${issue.slug}.png`
            : undefined
        }
        title={issue.title || 'Issue'}
      />

      <AppFixedHeader />

      <section>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <SectionLabel className="mb-3" name="Issue" />
              <div className="titlebar align-baseline mb-4">
                <h1 className="mr-4">{issue.title}</h1>
                {issue.issueSocialMediaAccount.length > 0 && (
                  <div className="share-links">
                    {issue.issueSocialMediaAccount.map((account, i) =>
                      account.twitterHandle ? (
                        <OutboundLink
                          className="twitter-link"
                          eventLabel={`https://twitter.com/${account.twitterHandle}`}
                          key={i}
                          to={`https://twitter.com/${account.twitterHandle}`}
                        >
                          <FontAwesomeIcon
                            icon={['fab', 'twitter']}
                            className="mr-1"
                            size="lg"
                          />
                          @{account.twitterHandle}
                        </OutboundLink>
                      ) : (
                        <OutboundLink
                          className="instagram-link"
                          eventLabel={`https://instagram.com/${account.instagramName}`}
                          key={i}
                          to={`https://instagram.com/${account.instagramName}`}
                        >
                          <FontAwesomeIcon
                            icon={['fab', 'instagram']}
                            className="mr-2"
                            size="lg"
                          />
                          {account.instagramName}
                        </OutboundLink>
                      ),
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>

          {get(issue, 'body') && (
            <Row className="mb-4">
              <Col xs={12}>
                <Content className="deck" content={issue.body} />
              </Col>
            </Row>
          )}

          {has(issue, 'featuredContent[0]') && (
            <FeaturedItems items={issue.featuredContent} />
          )}

          <Divider className="mt-4 mb-5" />

          <Row>
            <Col xs={12} md={4} lg={3}>
              <IssueFilters
                filters={["issues", "contentType", "author"]}
                parentID={parseInt(issue.id)}
                issueID={parseInt(issue.id)}
                contentTypeFilter={contentTypeFilter}
                authorFilter={authorFilter}
                team={team}
              />

              {team && (
                  <MediaQuery minWidth={768}>
                    <div>
                      <SectionLabel name="Team" className="mb-4" />
                      <div className="team-avatars">
                        <Row>
                          <Col xs={12}>
                            <AuthorList authors={team} itemClassName="mb-2" small />
                          </Col>
                        </Row>
                      </div>
                  </div>
                </MediaQuery>
              )}
            </Col>

            <Col xs={12} md={8} lg={9}>
              <IssuePageRelatedProducts {...props} parentID={parseInt(issue.id)} />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="my-5">
                <SubscribeBox iframeUrl={subscriptionUrl} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Divider className="mb-5 mt-2" />
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={7}>
              <h2>On Twitter</h2>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              {twitterHandles.length > 0 && (
                <ul className="list-inline mb-3">
                  {twitterHandles.map((handle, i) => (
                    <li className="list-inline-item" key={i}>
                      <a
                        href={`https://twitter.com/${handle}`}
                        className="twitter-handle"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'twitter']}
                          className="mr-1"
                        />
                        Follow @{handle}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <TwitterFeed handle={get(twitterHandles, '[0]', null)} />
            </Col>
          </Row>
        </Grid>
      </section>
    </div>
  );
};

export default IssuePage;
