import PropTypes from 'prop-types';

const tagShape = PropTypes.shape({
  body: PropTypes.string,
  id: PropTypes.string,
  issueHidden: PropTypes.bool,
  issueIcon: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  uri: PropTypes.string,
});

export default tagShape;
