import gql from 'graphql-tag';

const MapPageRelatedMaterialsQuery = gql`
query MapPageRelatedMaterialsQuery($relatedElement: [QueryArgument]) {
  entries(section: ["products", "events", "externalContent", "pressReleases", "inTheNews"], relatedTo: $relatedElement) {
    ... on products_products_Entry {
      title
      uri
      postDate
      productFeaturedImageUrl
      productPublicationUpdateDate
      productReadingTime

      productFeaturedImage {
        id
        title
        url
      }
      productType {
        slug
        id
        title
      }
    }

    ... on events_events_Entry {
      title
      uri

      eventPhotos {
        id
        url
        title
      }
    }

    ... on pressReleases_pressReleases_Entry {
      title
      uri
      postDate
    }

    ... on externalContent_default_Entry {
      externalName: title
      externalUrl: newsItemUrl
      externalType: publicationName

      image: newsItemFeaturedImage {
        url
        id
      }
    }

    ... on inTheNews_inTheNews_Entry {
      title
      uri: newsItemUrl
      publicationName

      newsItemFeaturedImage {
        url
        id
      }

      publicationLogo {
        url
        id
      }
    }
  }
}`;

export default MapPageRelatedMaterialsQuery;
