import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query PressReleasesAndMedia($slug: [String!]) {
    contactInfo: globalSet(handle: "companyInfo") {
      ... on companyInfo_GlobalSet {
        globalPhone
        globalFax
        globalEmail
      }
    }
    pressInfo: globalSet(handle: "companyInfo") {
      ... on companyInfo_GlobalSet {
        pressContact {
          id
          title
          ... on staff_staff_Entry {
            ...Staff
          }
        }
      }
    }
    pressRelease: entry(slug: $slug, section: "pressReleases") {
      id
      title
      slug
      sectionId
      sectionHandle
      ... on pressReleases_pressReleases_Entry {
        postDate
        authors {
          __typename
          id
          slug
          title
          ... on staff_staff_Entry {
            position
            twitterHandle
            uri
            photo {
              id
              url
            }
          }
          ... on externalAuthors_externalAuthors_Entry {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }
        body
        relatedProducts {
          id
          title
          uri
          typeId
          typeHandle
          __typename
          ... on products_products_Entry {
            productType {
              slug
            }
          }
          ... on inTheNews_inTheNews_Entry {
            newsItemUrl
          }
        }
        issues {
          id
          title
          ... on issues_Category {
            issueIcon
            issueSocialMediaAccount {
              ... on issueSocialMediaAccount_twitterAccount_BlockType {
                twitterHandle
              }
              ... on issueSocialMediaAccount_instagramAccount_BlockType {
                instagramName
              }
            }
          }
        }
      }
    }
  }

  ${StaffFragment}
`;
