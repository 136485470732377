import React, { Component } from 'react';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo'

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import issueFilterShape from '../../shapes/issueFilterShape';
import LoadingSpinner from '../../components/LoadingSpinner';
import PaginatedEntries from '../../components/PaginatedEntries';
import paginationShape from '../../shapes/paginationShape';
import withIssueFilter from '../../wrappers/withIssueFilter';
import withContentFilters from '../../wrappers/withContentFilters';
import withPagination from '../../wrappers/withPagination';
import { itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

class IssuePageRelatedProducts extends Component {
  static propTypes = {
    data: dataShape.isRequired,
    issueFilter: issueFilterShape.isRequired,
    pagination: paginationShape.isRequired,
  }

  constructor() {
    super();

    this.state = {
      loading: false,
    };
  }

  componentWillReceiveProps(nextProps)  {
    // If the issue filter has changed and we are refetching based on variable changes (i.e: not from the cache)
    // show the loading spinner so the pagination isn't displaying when we don't have up to date pagination info
    const currentContentType = get(this.props, 'contentTypeFilter.currentContentType');
    const currentIssue = get(this.props, 'issueFilter.currentIssueID');
    const currentSearchQuery = get(this.props, 'search.currentSearchQuery');
    const networkStatus = get(nextProps, 'data.networkStatus');
    const nextContentType = get(nextProps, 'contentTypeFilter.currentContentType');
    const nextIssue = get(nextProps, 'issueFilter.currentIssueID');
    const nextSearchQuery = get(nextProps, 'search.currentSearchQuery');

    if ((currentIssue !== nextIssue ||
         currentSearchQuery !== nextSearchQuery ||
         currentContentType !== nextContentType) && networkStatus === 2) {
      return this.setState({ loading: true });
    }

    return this.setState({ loading: false });
  }

  render() {
    const { data, pagination } = this.props;
    const { error, loading, relatedProducts, totalCount } = data;

    // Initial loading spinner, for when there's no data yet returned
    if ((loading) || this.state.loading) { return (<LoadingSpinner fullPage={false} />); }
    if (error) { return (<Error error={error} />); }

    const items = itemsFromEntries(relatedProducts);

    return (
      <PaginatedEntries
        items={items}
        loading={loading}
        noItemsMessage="No results found"
        pagination={pagination}
        totalItems={totalCount}
      />
    )
  }
};

const withGraphql = graphql(query, {
  options: ({
    contentTypeFilter: { currentContentType },
    authorFilter: { currentAuthor },
    issueFilter: { currentIssueID },
    parentID,
    match: { params },
    pagination
  }) => {
    const section = [];

    switch (currentContentType) {
      case 'products':
        section.push('products', 'series');
        break;
      case undefined:
        section.push('products', 'series', 'inTheNews', 'pressReleases', 'events');
        break;
      default:
        section.push(currentContentType);
    }

    const filters = [];

    if (currentAuthor) {
      filters.push(parseInt(currentAuthor, 10));
    }

    if (currentIssueID) {
      filters.push(currentIssueID);
    }

    const variables = {
      limit: pagination.limit || 10,
      offset: pagination.offset || 0,
      public: true,
      filters: filters.length ? filters : undefined,
      section,
      parentID,
    };

    return ({ variables });
  },
})(IssuePageRelatedProducts);

export default withContentFilters(
  withIssueFilter(
    withPagination(withGraphql, { limit: 10 })
  )
);
