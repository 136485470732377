import gql from 'graphql-tag';

export default gql`
  query EventsPageEventListQuery(
    $limit: Int,
    $offset: Int,
  ) {
    entries(
      limit: $limit,
      offset: $offset,
      orderBy: "eventStartDate desc",
      public: true,
      section: "events",
    ) {
      id
      slug
      title
      sectionId

      ... on events_events_Entry {
        eventStartDate

        eventPhotos {
          url
          id
        }

        eventIssue {
          id
          title
        }
      }
    }
  }
`;
