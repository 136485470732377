import React from 'react';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import PressPageComponent from '../../components/PressPage';

import query from './query';

const PressPage = ({ data, location }) => {
  const {
    contactInfo,
    error,
    loading,
    pressInfo,
  } = data;

  if (loading) { return (<LoadingSpinner />); }
  if (error) { return (<Error error={error} />); }

  return (
    <PressPageComponent
      contactInfo={contactInfo}
      pressInfo={pressInfo.pressContact}
      location={location}
    />
  );
};

export default graphql(query)(PressPage);
