import gql from 'graphql-tag';

export default gql`
  query EventPageRelatedContentQuery($slug: [String]) {
    event: entry(
      slug: $slug,
      section: "events",
    ) {
      id
      slug
      title

      ... on events_events_Entry {
        eventRelatedContent {
          ... on eventRelatedContent_externalContent_BlockType {
            __typename
            description

            issue {
              id
              title
            }

            image {
              id
              title
              url
            }

            externalUrl
            externalName
            externalType
          }

          ... on eventRelatedContent_entry_BlockType {
            entry {
              __typename
              id
              postDate
              slug
              title

              ... on events_events_Entry {
                eventStartDate

                eventIssue {
                  id
                  slug
                  title
                }

                eventPhotos {
                  id
                  url
                  title
                }
              }

              ... on products_products_Entry {
                productBlurb
                productReadingTime

                productFeaturedImage {
                  id
                  url
                  title
                }

                productFeaturedImageUrl

                productIssue {
                  id
                  slug
                  title
                }

                productType {
                  id
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;
