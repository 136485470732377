import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

const ProductPageQuery = gql`
  query HomepageQuery {
    entry(type:"homepage") {
      id

      ... on homepage_homepage_Entry {
        featuredContent {
          ... on featuredContent_externalContent_BlockType {
            id
            description

            issue {
              id
              slug
              title
            }

            image {
              id
              title
              url
            }

            externalUrl
            externalName
            externalType
          }

          ... on featuredContent_entry_BlockType {
            entry {
              __typename
              id
              postDate
              slug
              title
              uri

              ... on events_events_Entry {
                eventStartDate
                shortDescription

                eventIssue {
                  id
                  slug
                  title
                }

                eventPhotos {
                  id
                  url
                  title
                }
              }

              ... on inTheNews_inTheNews_Entry {
                authors {
                  __typename
                  id
                  slug
                  title

                  ... on staff_staff_Entry {
                    ...Staff
                  }

                  ... on externalAuthors_externalAuthors_Entry {
                    twitterHandle
                    position
                    photo {
                      id
                      url
                    }
                  }
                }

                blurb
                postDate
                publicationName
                publicationLogo {
                  url
                  id
                }
                newsItemUrl
                newsItemFeaturedImage {
                  id
                  url
                  title
                }

                issues {
                  id
                  slug
                  title
                }
              }

              ... on products_products_Entry {
                productBlurb
                productReadingTime

                productAuthors {
                  __typename
                  id
                  slug
                  title

                  ... on staff_staff_Entry {
                    ...Staff
                  }

                  ... on externalAuthors_externalAuthors_Entry {
                    twitterHandle
                    position
                    photo {
                      id
                      url
                    }
                  }
                }

                productFeaturedImage {
                  id
                  url
                  title
                }

                productFeaturedImageUrl

                productIssue {
                  id
                  slug
                  title
                }

                productType {
                  id
                  slug
                  title
                }
              }

              ... on series_series_Entry {
                seriesFeaturedImage {
                  id
                  url
                  title
                }
              }
            }
          }
        }
      }
    }
  }

  ${StaffFragment}
`;

export default ProductPageQuery;
