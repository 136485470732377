import gql from 'graphql-tag';

const CategoryListQuery = gql`
  query CategoryListQuery {
    categories(groupId: 3, level: 1) {
      id
      slug
      title
      ... on issues_Category {
        issueHidden
        issueIcon
      }
    }
  }
`;

export default CategoryListQuery;
