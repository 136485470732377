import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Count from './Count';
import tagShape from '../../shapes/tagShape';

import './IssueTag.css';

const IssueTag = ({ active, className, count, onClick, tag }) => (
  <div className={classNames('IssueTag', className, { active })} onClick={() => onClick(tag)}>
    <span className="title">
      {tag.title}
    </span>
    <Count>{count}</Count>
  </div>
);

IssueTag.propTypes = {
  /**
   * An optional flag to indicate if the tag is active.
   */
  active: PropTypes.bool,
  /**
   * An optional associated count.
   */
  count: PropTypes.number,
  /**
   * A method fired when a tag is clicked.
   */
  onClick: PropTypes.func,
  /**
   * The tag data.
   */
  tag: tagShape.isRequired,
};

IssueTag.defaultProps = {
  active: false,
  count: null,
  onClick: () => {},
}

export default IssueTag;
