import gql from 'graphql-tag';

export default gql`
  query IssuesNavQuery {
    top: categories(groupId: 3, level: 1) {
      id
      slug
      title
      uri
      ... on issues_Category {
        issueHidden
        issueIcon
      }
    }
    bottom: categories(groupId: 3, level: 2) {
      id
      slug
      title
      uri
      ... on issues_Category {
        issueHidden
        issueIcon
      }
    }
  }
`;
