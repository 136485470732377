import React from 'react';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive';
import { get, filter } from 'lodash-es';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import { OutboundLink } from 'react-ga';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import AuthorList from '../AuthorList';
import companyInfoShape from '../../shapes/companyInfoShape';
import Content from '../Content';
import Divider from '../Divider';
import productShape from '../../shapes/productShape';
import SocialSharing from '../SocialSharing';
import SectionLabel from '../SectionLabel';
import SubscribeBox from '../../containers/SubscribeBox';
import TopicLabel from '../TopicLabel';
import urlIsExternal from '../../utils/urlIsExternal';

import './PressReleasePage.css';

const PressReleasePage = ({
  contactInfo = {},
  pressInfo = [],
  pressRelease,
  related,
}) => {
  if (!pressRelease) {
    return null;
  }

  return (
    <div className="PressReleasePage">
      <AppHelmet title={pressRelease.title || 'Press Release'} />

      <AppFixedHeader
        mainIssue={
          filter(pressRelease.issues, (issue) => issue.issueIcon)[0] || null
        }
        sharing={{
          title: pressRelease.title,
        }}
        title={pressRelease.title}
      />

      <Grid fluid>
        <Row>
          <Col xs={12} xl={9} className="col-xs-print-12">
            <Row>
              <Col xs={12}>
                <SectionLabel
                  className="mb-4"
                  name="Press Release"
                  publishedAt={get(pressRelease, 'postDate', null)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h1 className="mb-4">{get(pressRelease, 'title', null)}</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <AuthorList
                  authors={get(pressRelease, 'authors', [])}
                  className="mb-4"
                  small
                />
              </Col>
              <Col xs={12} lg={8}>
                <Content
                  content={pressRelease.body ? pressRelease.body : ''}
                />
                <div className="mt-5">
                  <Row>
                    <Col xs={12}>
                      <SubscribeBox size={"small"} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} xl={3} className="pl-5 mt-2 col-xs-print-12">
            <Responsive minWidth={1200}>
              <SocialSharing
                className="mb-4 pull-right"
                title={pressRelease.title}
                trackClicks={true}
                postDate={get(pressRelease, 'postDate', null)}
                productType="Press Release"
              />
            </Responsive>

            {related && related.length > 0 && (
              <div>
                <h3>Related</h3>
                {related.map((product, i) => {
                  const url =
                    product.type.name === 'In The News'
                      ? product.newsItemUrl
                      : product.uri;

                  const label =
                    product.__typename === 'Events'
                      ? 'events'
                      : product.type.name;

                  return (
                    <div className="product" key={product.id}>
                      <TopicLabel name={label} />
                      <h5 className="mb-3">
                        {urlIsExternal(url) ? (
                          <OutboundLink eventLabel={url} to={url}>
                            {product.title}
                          </OutboundLink>
                        ) : (
                          <Link to={`/${url}`}>{product.title}</Link>
                        )}
                      </h5>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Divider className="my-5" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} className="col-xs-print-6">
            <h2 className="mb-3">General</h2>
            <p className="mb-5 mb-lg-0">
              {get(contactInfo, 'globalPhone') &&
                contactInfo.globalPhone.length > 0 && (
                  <span>
                    {contactInfo.globalPhone}
                    <br />
                  </span>
                )}
              {get(contactInfo, 'globalFax') &&
                contactInfo.globalFax.length > 0 && (
                  <span>
                    {contactInfo.globalFax} (fax)
                    <br />
                  </span>
                )}
              {get(contactInfo, 'globalEmail') &&
                contactInfo.globalEmail.length > 0 && (
                  <a
                    href={`mailto:${contactInfo.globalEmail}`}
                    className="link-secondary"
                  >
                    {contactInfo.globalEmail}
                  </a>
                )}
            </p>
          </Col>
          <Col xs={12} lg={4} className="col-xs-print-6">
            <h2 className="mb-3">Press Contact</h2>
            {pressInfo.map((contact) => (
              <p className="mb-5 mb-lg-0" key={contact.id}>
                {get(contact, 'title') && contact.title.length > 0 && (
                  <span>
                    <strong>{contact.title}</strong>
                    <br />
                  </span>
                )}
                {get(contact, 'position') && contact.position.length > 0 && (
                  <span>
                    <em>{contact.position}</em>
                    <br />
                  </span>
                )}
                {get(contact, 'phoneNumber') && contact.phoneNumber.length > 0 && (
                  <span>
                    {contact.phoneNumber}
                    <br />
                  </span>
                )}
                {get(contact, 'email') && contact.email.length > 0 && (
                  <a
                    href={`mailto:${contact.email}`}
                    className="link-secondary"
                  >
                    {contact.email}
                  </a>
                )}
              </p>
            ))}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

PressReleasePage.propTypes = {
  contactInfo: companyInfoShape,
  pressInfo: PropTypes.arrayOf(companyInfoShape),
  pressRelease: productShape,
};

export default PressReleasePage;
