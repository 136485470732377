import PropTypes from 'prop-types';

const issuesCategoryShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  issueIcon: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  uri: PropTypes.string,
});

export default issuesCategoryShape;
