import gql from 'graphql-tag';

export default gql`
  query IssueFiltersQuery(
    $issueID: Int,
    $searchQuery: String,
    $section: [String],
    $parentID: [QueryArgument],
  ) {
    allTopics: entryCount(
      relatedToCategories: [{id: $parentID}],
      public: true,
      section: $section,
    )

    categories(
      descendantOf: $issueID,
      groupId: 3,
    ) {
      id
      slug
      title
      uri
    }

    relatedEntries: entries(
      search: $searchQuery,
      relatedTo: $parentID,
      public: true,
      section: $section,
    ) {
      id

      ... on products_products_Entry {
        issues: productIssue {
          id
        }
      }

      ... on inTheNews_inTheNews_Entry {
        issues {
          id
        }
      }

      ... on pressReleases_pressReleases_Entry {
        issues {
          id
        }
      }

      ... on events_events_Entry {
        issues: eventIssue {
          id
        }
      }

      ... on series_series_Entry {
        issues {
          id
        }
      }
    }
  }
`;
