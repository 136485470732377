import React from 'react';
import { graphql } from 'react-apollo';

import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import PaginatedEntries from '../../components/PaginatedEntries';
import withPagination from '../../wrappers/withPagination';
import { itemsFromEntries } from '../../utils/itemFromEntry';

import query from './query';

const EventsPageEventList = ({ data, pagination }) => {
  const { entries, error, loading } = data;

  if (loading && !entries) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }
  if (!entries) { return null; }

  const items = itemsFromEntries(entries);

  // Total count is lowered by one to account for the featured item that's been excluded
  return (
    <PaginatedEntries
      items={items}
      loading={loading}
      pagination={pagination}
      totalItems={entries.length - 1}
    />
  );
};

const withGraphql = graphql(query, {
  options: ({ pagination }) => {
    return ({
      variables: {
        limit: pagination.limit || 5,
        // Add one to the offset so that it's not displaying the item that's already featured
        offset: pagination.offset + 1 || 1,
      },
    })
  },
})(EventsPageEventList);

export default withPagination(withGraphql, { limit: 5 });
