import gql from 'graphql-tag';

export default gql`
  query SearchPageQuery(
    $limit: Int,
    $offset: Int,
    $relatedElement: [QueryArgument],
    $searchQuery: String,
    $section: [String],
  ) {
    totalCount: entryCount(
      public: true,
      relatedTo: $relatedElement,
      search: $searchQuery,
      section: $section,
    )

    results: entries(
      limit: $limit,
      offset: $offset,
      orderBy: "postDate desc",
      public: true,
      relatedTo: $relatedElement,
      search: $searchQuery,
      section: $section,
    ) {
      __typename
      id
      postDate
      slug
      title
      uri

      ... on events_events_Entry {
        eventStartDate

        eventIssue {
          slug
          title
          id
        }

        eventPhotos {
          url
          id
        }
      }

      ... on products_products_Entry {
        productReadingTime

        productIssue {
          slug
          title
          id
        }

        productFeaturedImage {
          url
          id
        }

        productFeaturedImageUrl

        productType {
          slug
          id
          title
        }

        productAuthors {
          __typename
          id
          slug
          title

          ... on staff_staff_Entry {
            ...staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            ...external
          }
        }
      }

      ... on series_series_Entry {
        seriesBlurb

        seriesFeaturedImage {
          url
          id
        }

        issues {
          slug
          title
          id
        }

        authors {
          __typename
          id
          slug
          title

          ... on staff_staff_Entry {
            ...staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            ...external
          }
        }
      }

      ... on pressReleases_pressReleases_Entry {
        postDate

        issues {
          title
          id
        }
      }

      ... on inTheNews_inTheNews_Entry {
        publicationName
        newsItemUrl

        newsItemFeaturedImage {
          url
          id
        }

        publicationLogo {
          url
          id
        }

        issues {
          title
          id
        }
      }
    }
  }

  fragment staff on staff_staff_Entry {
    position
    photo {
      url
      id
    }
  }

  fragment external on externalAuthors_externalAuthors_Entry {
    twitterHandle
    id
    position
    photo {
      url
      id
    }
  }
`;
