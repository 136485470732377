import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
  query EventsPageQuery {
    event: entry(
      orderBy: "eventStartDate desc",
      public: true,
      section: "events",
    ) {
      id
      slug
      title

      ... on events_events_Entry {
        eventBriteId
        eventEndDate
        eventStartDate
        eventLocationAddress
        eventLocationCity
        eventLocationState
        eventLocationName
        eventLocationZip

        eventContact {
          __typename
          id
          title

          ... on staff_staff_Entry {
            ...Staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            email
            position
          }
        }

        eventPhotos {
          url
          id
        }

        eventIssue {
          id
          title
        }
      }
    }
  }

  ${StaffFragment}
`;
