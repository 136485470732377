import React from 'react';

import Content from '../../Content';
import './MapPageMethodology.css';

const MapPageMethodology = ({ content }) => (
  <section className="MapPageMethodology mx-auto">
      <div className="content-container mx-auto">
        <div className="heading">
          <h2 className="text-delta">{content.methodologyHeading}</h2>
        </div>
        <div className="text text-paragraph">
          <Content content={content.methodologyBody} />
        </div>
      </div>
  </section>
)

export default MapPageMethodology;
