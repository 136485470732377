import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Author from '../Author';
import authorShape from '../../shapes/authorShape';

import './AuthorList.css';

const AuthorList = ({ authors = [], className, itemClassName, onClick, small = false, simple = false, showImage = true }) => (
  <ul className={classNames('AuthorList', simple ? 'simple' : '', className)}>
    {authors.map((author, i) =>
      <li key={author.id}>
        {simple && author &&
          <>
              <strong>{author.title}, </strong>
              <span>{author.position}</span>
          </>
        }
        {!simple && author &&
          <Author
            author={author}
            className={itemClassName}
            onClick={onClick}
            small={small}
            showImage={showImage}
          />
        }
      </li>
    )}
  </ul>
);

AuthorList.propTypes = {
  /**
   * The list of tags to display.
   */
  authors: PropTypes.arrayOf(authorShape).isRequired,
  /**
   * The className to apply to individual items.
   */
  itemClassName: PropTypes.string,
  /**
   * A callback function when a tag is selected.
   */
  onClick: PropTypes.func,
  /**
   * Whether or not to display the Author as small.
   */
  small: PropTypes.bool,
  /**
   * A Boolean flag for showing or hiding the author images.
   */
  showImage: PropTypes.bool,
};

export default AuthorList;
