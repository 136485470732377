import gql from 'graphql-tag';

export default gql`
  query aboutQuery {
    aboutContent: entry(section: "about") {
      ... on about_about_Entry {
        aboutBlock {
          __typename
          ... on aboutBlock_aboutBlock_BlockType {
            blockTitle
            blockText
            blockImage {
              url
            }
            blockMediaAlignment
            blockYoutubeId
          }
        }
      }
    }
  }
`;
