import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import get from 'lodash-es/get';

import Content from '../Content';

const CaseStudy = ({ caseStudy }) => (
  <div className="CaseStudy">
    <div>
      <Row>
        <Col xs={12}>
          <h2 className="mb-4">{caseStudy.title}</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Content content={get(caseStudy, 'body', 'No content.')} />
        </Col>
      </Row>
    </div>
  </div>
);

export default CaseStudy;
