import gql from 'graphql-tag';

export default gql`
  query AboutPageBioIssueFiltersQuery($slug: [String]) {
    staff: entry(slug: $slug, type: "staff") {
      id
      slug
    }

    products: entries(
      section: ["products", "series"],
      public: true,
      relatedToEntries: [{slug: $slug}]
    ) {
      id

      ... on products_products_Entry {
        issues: productIssue {
          id
          title
          slug
        }
      }

      ... on series_series_Entry {
        issues: issues {
          id
          title
          slug
        }
      }
    }

    media: entries(
      section: "inTheNews",
      public: true,
      relatedToEntries: [{slug: $slug}]
    ) {
      id

      ... on inTheNews_inTheNews_Entry {
        issues {
          id
          title
          slug
        }
      }
    }
  }
`;
