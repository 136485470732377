import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';

export default gql`
query {
  leadership: entries(section: "staff", descendantOf: 4934) {
    ...Staff
    ...StaffEntry
  }

  staff: entries(section: "staff", descendantOf: 4935) {
    ...Staff
    ...StaffEntry
  }

  trustees: entries(section: "staff", descendantOf: 4936) {
    ...Staff
    ...StaffEntry
  }

  chairs: entries(section: "staff", descendantOf: 4937) {
    ...Staff
    ...StaffEntry
  }

  teams: entries(section: "team", hiddenTeam: false) {
    id
    slug
    title
    team {
      ...Staff
      ...StaffEntry
    }
  }
}

fragment StaffEntry on EntryInterface {
  ... on staff_staffReference_Entry {
    staffReference {
      __typename
      ...Staff
    }
  }

  ... on staff_staff_Entry {
    ...Staff
  }
}

  ${StaffFragment}
`;
