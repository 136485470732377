import React from 'react';
import classNames from 'classnames';
import get from 'lodash-es/get';
import { NavLink, Route } from 'react-router-dom';
import { Row, Col } from 'react-flexbox-grid';

import AppHelmet from '../../AppHelmet';
import Career from '../../../containers/Career';
import Content from '../../Content';
import Divider from '../../Divider';
import Notice from '../Notice';
import SideNavList from '../../SideNavList';
import TopicLabel from '../../TopicLabel';

const CareersContent = ({ data, positions, match }) => {
  const careers = positions.filter(position => position.positionType[0].title === 'Careers');
  const content = get(data, 'body', 'No content.');

  return(
    <div className="CareersContent">
      <AppHelmet title="Careers" />

      <Row className="mb-4">
        <Col xs={12} lg={8} className="mb-3 mb-lg-0">
          <Content content={content} />
        </Col>
        <Col xs={12} lg={4}>
          <Notice />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Divider className="mb-4 mb-lg-5" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4} className="pr-lg-5">
          <TopicLabel name="Current Openings" className="mb-2" />
          <SideNavList className="mb-4">
            <ul className="list-unstyled">
              {careers.map((career, index) => {
                const active = RegExp('^/careers/?$').test(match.url) && index === 0;
                return(
                  <li key={career.id}>
                    <NavLink to={{pathname: `/careers/${career.slug}`, state: { noScroll: true }}} className={classNames({ active })}>
                      {career.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </SideNavList>
        </Col>
        <Col xs={12} lg={8}>
          <Route exact path="/careers" component={() => {
            if (careers.length > 0){
              return(
                <Career slug={careers[0].slug} />
              );
            }
            return(
              <h1>No Current Openings were found.</h1>
            );
          }} />
          <Route exact path="/careers/:slug" component={() => (
            <Career slug={match.params.slug} />
          )} />
        </Col>
      </Row>
    </div>
  )
};

export default CareersContent;
