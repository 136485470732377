import gql from 'graphql-tag';

export default gql `
  query {
    globalSets(handle: "redirects") {
    ... on redirects_GlobalSet {
      globalRedirects {
        ... on globalRedirects_redirect_BlockType {
          id
          requestedUrl
          redirectUrl
        }
      }
    }
  }
  }
`;
