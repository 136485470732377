import React from 'react';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';
import Content from '../../components/Content';

import query from './query';

const LegalPagePrivacyPolicy = ({ data }) => {
  const { error, legal, loading } = data;

  if (loading) { return (<LoadingSpinner fullPage={false} />); }
  if (error) { return (<Error error={error} />); }

  const content = legal[0].privacyPolicy;

  if (!content) { return null; }

  return (
    <Content
      content={content}
    />
  );
};

LegalPagePrivacyPolicy.propTypes = {
  data: dataShape.isRequired,
};

export default graphql(query)(LegalPagePrivacyPolicy);
