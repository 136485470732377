import gql from 'graphql-tag';

export default gql`
  query IssuesRelatedQuery(
    $issueIDs: [QueryArgument],
  ) {
    categories(
      id: $issueIDs,
      groupId: 3,
      level: 2
    ) {
      id
      slug
      title
      uri
    }

    relatedEntries: entries(
      public: true,
      relatedToCategories: [{id: $issueIDs}]
      section: ["products", "inTheNews", "events", "pressReleases"]
    ) {
      id

      ... on products_products_Entry {
        issues: productIssue {
          id
        }
      }

      ... on inTheNews_inTheNews_Entry {
        issues {
          id
        }
      }

      ... on pressReleases_pressReleases_Entry {
        issues {
          id
        }
      }

      ... on events_events_Entry {
        issues: eventIssue {
          id
        }
      }
    }
  }
`;
