import gql from 'graphql-tag';
import { RelatedProductsSeries, RelatedProductsSeriesGroup } from '../../fragments/RelatedProducts';

export default gql`
  query SeriesPageFiltersQuery($slug: [String]) {
    entry(section: "series", slug: $slug) {
      id

      ... on series_series_Entry {
        seriesGroups {
          title

          ... on seriesGroups_group_Entry {
            description

            ...RelatedProductsSeriesGroup
          }
        }
        ...RelatedProductsSeries
      }
    }
  }

  ${RelatedProductsSeries}
  ${RelatedProductsSeriesGroup}
`;
