import React from 'react';
import { get } from 'lodash-es';
import { graphql } from 'react-apollo';

import DonatePageComponent from '../../components/DonatePage';
import dataShape from '../../shapes/dataShape';
import Error from '../../components/Error';
import LoadingSpinner from '../../components/LoadingSpinner';

import query from './query';

const Donate = ({ data }) => {
  const { error, loading } = data;

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return <Error error={error} />;
  }

  const companyInfo = get(data, 'globalSets');
  const donateContent = get(data, 'donateContent');

  return <DonatePageComponent companyInfo={companyInfo} donateContent={donateContent} />;
};

Donate.propTypes = {
  data: dataShape,
};

export default graphql(query)(Donate);
