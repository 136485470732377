import React from 'react';
import moment from 'moment-timezone';
import Responsive from 'react-responsive';
import sanitizeHtml from 'sanitize-html';
import { filter, get } from 'lodash-es';
import { Grid, Row, Col } from 'react-flexbox-grid';

import AppFixedHeader from '../../containers/AppFixedHeader';
import AppHelmet from '../AppHelmet';
import Button from '../Button';
import Content from '../Content';
import Divider from '../Divider';
import EventContact from '../EventContact';
import EventDetails from '../EventDetails';
import EventFeaturedImage from '../EventFeaturedImage';
import EventPageRelatedContent from '../../containers/EventPageRelatedContent';
import fallbackImage from '../../utils/fallbackImage';
import RsvpModal from '../RsvpModal';
import SectionLabel from '../SectionLabel';
import SocialSharing from '../SocialSharing';
import VideoEmbed from '../VideoEmbed';
import { Desktop, Mobile } from '../Devices';

import './EventPage.css';

const EventPage = ({ event, defaultPressContact }) => {
  if (!event) { return null; }

  const eventContact = event.eventContact && event.eventContact[0] ? event.eventContact[0] : null;
  const pressContact = event.eventHidePressContact ? null : defaultPressContact;

  return (
    <div className="EventPage">
      <AppHelmet
        description={sanitizeHtml(get(event, 'eventDescription'), { allowedTags: [] })}
        image={get(event, 'eventPhotos[0].url') ? `https:${event.eventPhotos[0].url}` : fallbackImage(event.eventIssue.map(issue => issue.slug))}
        title={event.title || 'Events'}
      />

      <AppFixedHeader
        mainIssue={filter(event.eventIssue, issue => issue.issueIcon)[0] || null}
        sharing={{
          title: event.title
        }}
        title={event.title}
      />

      <Grid fluid>
        <Row>
          <Col xs={12} xl={9} className="mb-3">
            <SectionLabel
              classname="mb-2"
              name="Event"
              date={event.eventStartDate}
            />

            <h1 className="mb-4">{event.title}</h1>

            {event.featuredVideo.length > 0 ? (
              <VideoEmbed
                aspectRatio="16by9"
                url={
                  event.featuredVideo[0].youtubeId
                    ? `https://www.youtube.com/embed/${event.featuredVideo[0].youtubeId}`
                    : event.featuredVideo[0].facebookVideoUrl
                }
              />
            ) : (
              <EventFeaturedImage event={event} />
            )}
          </Col>

          <Responsive minWidth={1200}>
            <Col xs={12} xl={3} className="pl-5 mt-2">
              <SocialSharing
                className="mb-4 pull-right"
                title={event.title}
                trackClicks={true}
                postDate={event.postDate}
                productType="Event"
              />

              <EventDetails event={event} />
            </Col>
          </Responsive>
        </Row>

        <Row className="mb-5">
          <Col xs={12} xl={9}>
            <Row>
              <Col xs={12} xl={4}>
                <Row>
                  <Col xs={12} md={4} xl={12} className="hidden-print">
                    {event.eventStartDate > moment().unix() && event.eventBriteId && (
                      <RsvpModal eventBriteId={event.eventBriteId}>
                        <Desktop>
                          <Button
                            className="text-uppercase mb-4"
                            primary
                            large
                          >
                            RSVP For This Event
                          </Button>
                        </Desktop>

                        <Mobile>
                          <Button
                            block
                            className="text-uppercase mb-4"
                            primary
                            large
                          >
                            RSVP For This Event
                          </Button>
                        </Mobile>
                      </RsvpModal>
                    )}
                  </Col>

                  <Responsive maxWidth={1199}>
                    <Col xs={12} md={4} xl={12}>
                      <EventDetails event={event} />
                    </Col>
                  </Responsive>

                  {(eventContact || pressContact) && (
                    <Col xs={12} md={4} xl={12} className="mb-4">
                      {eventContact && (
                        <EventContact contact={event.eventContact[0]} />
                      )}

                      {pressContact && (
                        <EventContact
                          contact={pressContact}
                          title="Press Contact"
                        />
                      )}
                    </Col>
                  )}
                </Row>
              </Col>

              <Col xs={12} xl={8} className="col-xs-print-12">
                <Content
                  content={event.eventDescription}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Divider className="mb-4" />
          </Col>
          <Col xs={12}>
            <EventPageRelatedContent />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default EventPage;
