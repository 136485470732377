import gql from 'graphql-tag';

import { RelatedProductsSeries } from '../../fragments/RelatedProducts';

const SeriesSideNavQuery = gql`
  query SeriesSideNavQuery($id: [QueryArgument]) {
    series: entries(section: "series", relatedToEntries: [{ id: $id }]) {
      ... on series_series_Entry {
        title
        uri
        seriesFeaturedImage {
          id
          url
          title
        }
        ...RelatedProductsSeries
      }
    }
  }

  ${RelatedProductsSeries}
`;

export default SeriesSideNavQuery;
