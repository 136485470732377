import gql from 'graphql-tag';

import StaffFragment from '../../fragments/Staff';
import { RelatedProductsSeries, RelatedProductsSeriesGroup } from '../../fragments/RelatedProducts';

const SeriesPageQuery = gql`
  query SeriesPageQuery($slug: [String]) {
    entry(type: "series", slug: $slug, siteFeed: ["mainSiteOnly",""]) {
      id
      slug
      title
      uri

      ... on series_series_Entry {
        body

        seriesFeaturedImage {
          id
          url
          title
        }

        issues {
          ... on issues_Category {
            id
            slug
            issueIcon
            title
          }
        }

        seriesGroups {
          title

          ... on seriesGroups_group_Entry {
            description

            ...RelatedProductsSeriesGroup
          }
        }

        authors {
          __typename
          id
          slug
          title

          ... on staff_staff_Entry {
            ...Staff
          }

          ... on externalAuthors_externalAuthors_Entry {
            twitterHandle
            position
            photo {
              id
              url
            }
          }
        }

        ...RelatedProductsSeries
      }
    }
  }

  ${RelatedProductsSeries}
  ${StaffFragment}
  ${RelatedProductsSeriesGroup}
`;

export default SeriesPageQuery;
